import { styled } from "linaria/react";
export const AppContainer = styled.div `
  & {
    * {
      box-sizing: border-box;
      -webkit-overflow-scrolling: auto;
    }
    *:focus {
      outline: none;
    }

    @keyframes pulsateOpacity {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    @font-face {
      font-family: "Trade-Gothic";
      src: url("https://nmr-frontend.s3.amazonaws.com/fonts/trade-gothic.otf") format("opentype");
      font-style: normal;
    }

    @font-face {
      font-family: "Diatype";
      src: url("https://nmr-frontend.s3.amazonaws.com/fonts/diatype-regular.otf") format("otf"),
        url("https://nmr-frontend.s3.amazonaws.com/fonts/diatype-regular.woff") format("woff"),
        url("https://nmr-frontend.s3.amazonaws.com/fonts/diatype-regular.woff2") format("woff2");
      font-style: normal;
    }

    @font-face {
      font-family: "Diatype-Light";
      src: url("https://nmr-frontend.s3.amazonaws.com/fonts/diatype-light.otf") format("otf"),
        url("https://nmr-frontend.s3.amazonaws.com/fonts/diatype-light.woff") format("woff"),
        url("https://nmr-frontend.s3.amazonaws.com/fonts/diatype-light.woff2") format("woff2");
      font-style: light;
    }

    @font-face {
      font-family: "Diatype-Italic";
      src: url("https://nmr-frontend.s3.amazonaws.com/fonts/diatype-regular-italic.otf") format("otf"),
        url("https://nmr-frontend.s3.amazonaws.com/fonts/diatype-regular-italic.woff") format("woff"),
        url("https://nmr-frontend.s3.amazonaws.com/fonts/diatype-regular-italic.woff2") format("woff2");
      font-style: normal;
    }

    @font-face {
      font-family: "Diatype-Light-Italic";
      src: url("https://nmr-frontend.s3.amazonaws.com/fonts/diatype-light-italic.otf") format("otf"),
        url("https://nmr-frontend.s3.amazonaws.com/fonts/diatype-light-italic.woff") format("woff"),
        url("https://nmr-frontend.s3.amazonaws.com/fonts/diatype-light-italic.woff2") format("woff2");
      font-style: light;
    }

    position: relative;
    color: #000;
    width: 100vw;
    background: #fff;
    -webkit-tap-highlight-color: transparent;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -ms-font-smoothing: antialiased;
    font-smoothing: antialiased;
    -webkit-text-rendering: optimizeLegibility;
    -moz-text-rendering: optimizeLegibility;
    -ms-text-rendering: optimizeLegibility;
    text-rendering: optimizeLegibility;

    .pulsate {
      animation: 0.5s pulsateOpacity linear infinite;
    }
  }
`;
