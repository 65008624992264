import React from "react";
import { styled } from "linaria/react";
const Icon = styled.a `
  width: 18px;
  height: 18px;
  display: block;
  & + a {
    margin-left: 16px;
  }
  background: ${(props) => `url("${props.icon}") no-repeat center center`};
  background-size: contain;
`;
const SocialIcon = ({ icon, link }) => {
    const iconUrl = `https://nmr-frontend.s3.amazonaws.com/icons/${icon}.svg`;
    return React.createElement(Icon, { icon: iconUrl, href: link });
};
export { SocialIcon };
