import React, { useRef, useState, useEffect } from "react";
import { Colors, PORTRAIT, LANDSCAPE } from "@styles";
import { styled } from "linaria/react";
import { Text } from "@common";
import { mq } from "@styles";
import Draggable from "react-draggable";
import { useHistory, useLocation } from "react-router";
import { NavActions } from "@actions";
import { connect } from "react-redux";
import { useInViewport } from "react-in-viewport";
const MQ = mq;
const ModuleWrap = styled.div `
  width: 100vw;
  background: ${(props) => (props.bgColor ? Colors[props.bgColor] : ``)};
  background-size: contain;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
  padding: 0;
  height: 630px;

  ${MQ.maxSm} {
    height: 480px;
    img + div {
      margin-left: 0;
    }
  }
`;
const Header = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 82px;
  position: absolute;
  left: 48px;
  top: 48px;
  z-index: ${(props) => (props.menuShow ? 2 : 3)};
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
  &:after {
    content: "";
    position: absolute;
    background: #fff;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    top: calc(50% - 5px);
    left: 38px;
  }

  ${MQ.maxSm} {
    &:after {
      width: 5px;
      height: 5px;
      border-radius: 2.5px;
      top: 21.5px;
      left: 21.5px;
    }
  }

  img {
    border-radius: 50%;
    position: relative;
    animation: rotation 30s infinite linear;
    width: 85px;
  }

  > div:first-child {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 82px;
    flex: 4;
  }

  > div:last-child {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 82px;
    flex: 1;
    a {
      margin-top: 24px;
    }
  }

  ${MQ.md} {
    > div:last-child {
      a {
        margin-top: 16px;
      }
    }
  }

  ${PORTRAIT} {
    > div:last-child {
      a {
        margin-top: 16px;
      }
    }
  }

  ${MQ.sm} {
    > div:last-child {
      a {
        margin-top: 16px;
      }
    }
  }

  ${LANDSCAPE} {
    > div:last-child {
      a {
        margin-top: 16px;
      }
    }
  }

  ${MQ.maxSm} {
    width: calc(100% - 48px);
    top: 24px;
    left: 24px;

    > div:last-child {
      flex: 1;
      a {
        margin-top: 8px;
      }
    }

    > div:first-child {
      flex-direction: column;
      align-items: flex-start;
      flex: 1;
      img {
        margin-bottom: 16px;
        width: 48px;
      }

      > div {
        display: flex;
        flex-direction: column;
      }
    }
  }
`;
const AudioDetails = styled.div `
  position: absolute;
  left: 142px;
  top: 48px;
  height: 82px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: ${(props) => (props.menuShow ? 2 : 3)};
  max-width: calc(100vw - 240px - 142px - 24px);
  flex-direction: column;

  span {
    font-weight: 500;
  }

  ${MQ.maxSm} {
    top: 82px;
    left: 24px;
    width: 100%;
    align-items: flex-start;
    flex-direction: column;
    max-width: calc(100vw - 24px);
  }
`;
const Waves = styled.div `
  height: 500px;
  width: 100%;
  position: absolute;
  z-index: 2;
  top: 130px;
  left: 0;
  background-image: ${(props) => props.paused
    ? 'url("https://nmr-frontend.s3.amazonaws.com/icons/red-sound-still.png")'
    : 'url("https://nmr-frontend.s3.amazonaws.com/icons/red-sound-visualizer.gif")'};
  cursor: ${(props) => (props.pointer ? "pointer" : "auto")};
  background-position: bottom center;
  background-repeat: repeat-x;

  ${MQ.maxSm} {
    height: 480px;
    top: 0;
  }
`;
const PauseButton = styled.div `
  cursor: pointer;
  position: absolute;
  left: calc(50% - 50px);
  top: calc(50% - 64px);
  width: 90px;
  height: 65px;
  background-image: url("https://nmr-frontend.s3.amazonaws.com/icons/pause-btn.svg");
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 3;

  ${PORTRAIT} {
    left: calc(50% - 36px);
    top: calc(50% - 46px);
    width: 72px;
    height: 92px;
  }

  ${MQ.maxSm} {
    left: calc(50% - 36px);
    top: calc(50% - 46px);
    width: 72px;
    height: 92px;
  }
`;
const PlayButton = styled.div `
  cursor: pointer;
  position: absolute;
  left: calc(50% - 64px);
  top: calc(50% - 64px);
  width: 128px;
  height: 128px;
  background-image: url("https://nmr-frontend.s3.amazonaws.com/icons/audio-play-btn.svg");
  background-size: contain;
  background-repeat: no-repeat;
  z-index: ${(props) => (props.menuShow ? 2 : 3)};

  ${PORTRAIT} {
    left: calc(50% - 46px);
    top: calc(50% - 46px);
    width: 92px;
    height: 92px;
  }

  ${MQ.maxSm} {
    left: calc(50% - 46px);
    top: calc(50% - 46px);
    width: 92px;
    height: 92px;
  }
`;
const Progress = styled.progress `
  color: ${Colors.red};
  width: 100%;
  height: 100%;
  -webkit-appearance: none;
  appearance: none;

  ${PORTRAIT} {
    color: ${Colors.blue};
  }

  &[value] {
    background-color: ${Colors.blue};
    color: ${Colors.blue};
    height: 100%;
    position: absolute;
    width: 100%;
    top: 0;

    ${PORTRAIT} {
      background-color: transparent;
      color: ${Colors.red};
    }
  }

  &[value]::-webkit-progress-bar {
    background-color: ${Colors.blue};
    color: ${Colors.blue};
    height: 100%;
    position: absolute;
    width: 100%;
    top: 0;

    ${PORTRAIT} {
      background-color: transparent;
      color: transparent;
    }
  }

  &[value]::-webkit-progress-value {
    background-color: ${Colors.red};
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 2;
    background-image: ${(props) => props.paused
    ? 'url("https://nmr-frontend.s3.amazonaws.com/icons/blue-sound-still.png")'
    : 'url("https://nmr-frontend.s3.amazonaws.com/icons/blue-sound-visualizer.gif")'};
    background-position: bottom center;
    background-repeat: repeat-x;

    ${PORTRAIT} {
      background-color: ${Colors.red};
    }
  }
`;
const ProgressWrap = styled.div `
  width: 100%;
  height: 100%;
`;
const PlayerWrap = styled.div `
  height: 100%;
  width: 100%;
`;
const ProgressLine = styled.div `
  display: block;
  height: 100%;
  width: 20px;
  cursor: pointer;
  position: relative;
  z-index: 3;
  left: -10px;
  transform: ${(props) => props.translateX ? `translate(${props.translateX}px, 0px)` : `translate(0px, 0px)`} !important;
`;
const ProgressTime = styled.div `
  display: block;
  height: 100%;
  width: 20px;
  cursor: pointer;
  position: absolute;
  z-index: 3;
  left: 10px;
  top: 50%;
  transform: ${(props) => props.translateX ? `translate(${props.translateX}px, 0px)` : `translate(0px, 0px)`} !important;
`;
const PlaySpan = styled.span `
  cursor: pointer;
`;
const PauseIcon = styled.span `
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url("https://nmr-frontend.s3.amazonaws.com/icons/pause.svg") no-repeat center center;
`;
const PlayIcon = styled.span `
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url("https://nmr-frontend.s3.amazonaws.com/icons/play.svg") no-repeat center center;
`;
const ClickSpan = styled.span `
  cursor: pointer;
`;
const PauseWrap = styled.div `
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  cursor: pointer;
`;
const PlayWrap = styled.div `
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.35);
  z-index: ${(props) => (props.menuShow ? 2 : 3)};
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: 0.5s opacity ease-in-out;
`;
const calculateCurrentValue = (currentTime) => {
    const currentHour = Math.floor((currentTime / 3600) % 24), currentMinute = Math.floor((currentTime / 60) % 60), currentSecondsLong = currentTime % 60, currentSeconds = currentSecondsLong.toFixed();
    const formattedTime = (currentHour === 0 ? "00" : currentHour < 10 ? "0" + currentHour : currentHour) +
        ":" +
        (currentMinute === 0 ? "00" : currentMinute < 10 ? "0" + currentMinute : currentMinute) +
        ":" +
        (currentSecondsLong < 10 ? "0" + currentSeconds : currentSeconds);
    return formattedTime;
};
const XAudioModule = ({ bgColor, title, img, waveColor, src, linkText, linkUrl, changeNav, nav }) => {
    const history = useHistory();
    const pathname = useLocation().pathname.toLowerCase();
    const audioRef = useRef(null);
    const progressRef = useRef(null);
    const progressDotRef = useRef(null);
    const [time, setTime] = useState("00:00");
    const [duration, setDuration] = useState("00:00");
    const [paused, setPaused] = useState(true);
    const [translateX, setTranslateX] = useState(0.0);
    const [hovering, setHovering] = useState(false);
    const [played, setPlayed] = useState(false);
    const [showTime, setShowTime] = useState(false);
    useEffect(() => {
        if (audioRef.current) {
            const player = audioRef.current;
            player === null || player === void 0 ? void 0 : player.addEventListener("timeupdate", () => {
                var _a;
                const length = player.duration;
                if (duration === "00:00") {
                    setDuration(calculateCurrentValue(length));
                }
                const currentTime = player.currentTime;
                if ((_a = progressRef === null || progressRef === void 0 ? void 0 : progressRef.current) === null || _a === void 0 ? void 0 : _a.offsetWidth) {
                    setTranslateX((currentTime / length) * progressRef.current.offsetWidth);
                }
                setTime(calculateCurrentValue(currentTime));
                if (progressRef.current) {
                    const localValue = currentTime / length;
                    progressRef.current.value = localValue;
                    progressRef.current.addEventListener("click", function (event) {
                        var _a;
                        const localPercentage = event.offsetX / this.offsetWidth;
                        player.currentTime = localPercentage * player.duration;
                        if (progressRef.current) {
                            progressRef.current.value = localPercentage / 100;
                            if ((_a = progressRef === null || progressRef === void 0 ? void 0 : progressRef.current) === null || _a === void 0 ? void 0 : _a.offsetWidth) {
                                setTranslateX(localPercentage * progressRef.current.offsetWidth);
                            }
                        }
                    });
                }
            });
        }
    }, [audioRef]);
    const isTouch = "ontouchstart" in document.documentElement;
    const moduleRef = useRef(null);
    const pausedRef = useRef(false);
    const { inViewport, enterCount, leaveCount } = useInViewport(moduleRef, { threshold: 0.5 }, { disconnectOnLeave: false }, {});
    // @ts-ignore
    window.onkeydown = function (e) {
        var elem = e.target.nodename;
        if (elem !== "TEXTAREA" && elem != "INPUT") {
            return !(e.keyCode == 32);
        }
    };
    document.body.onkeyup = function (e) {
        var _a, _b;
        if (e.keyCode == 32 && !isTouch) {
            if (pausedRef.current) {
                (_a = audioRef === null || audioRef === void 0 ? void 0 : audioRef.current) === null || _a === void 0 ? void 0 : _a.play();
                setPaused(false);
                pausedRef.current = false;
            }
            else {
                (_b = audioRef === null || audioRef === void 0 ? void 0 : audioRef.current) === null || _b === void 0 ? void 0 : _b.pause();
                setPaused(true);
                pausedRef.current = true;
            }
        }
        e.preventDefault();
        return false;
    };
    return (React.createElement(ModuleWrap, { ref: moduleRef, bgColor: bgColor, onMouseEnter: () => {
            setHovering(true);
        }, onMouseLeave: () => {
            setHovering(false);
        } },
        paused ? (!played ? (!isTouch ? (React.createElement(PlayWrap, { show: hovering, menuShow: !!(nav === null || nav === void 0 ? void 0 : nav.menuShow) },
            React.createElement(PlayButton, { menuShow: !!(nav === null || nav === void 0 ? void 0 : nav.menuShow), onClick: () => {
                    var _a;
                    (_a = audioRef === null || audioRef === void 0 ? void 0 : audioRef.current) === null || _a === void 0 ? void 0 : _a.play();
                    setPlayed(true);
                    setPaused(false);
                    pausedRef.current = false;
                } }))) : (React.createElement(PlayButton, { menuShow: !!(nav === null || nav === void 0 ? void 0 : nav.menuShow), onClick: () => {
                var _a;
                (_a = audioRef === null || audioRef === void 0 ? void 0 : audioRef.current) === null || _a === void 0 ? void 0 : _a.play();
                setPlayed(true);
                setPaused(false);
                pausedRef.current = false;
            } }))) : (React.createElement(PauseButton, { onClick: () => {
                var _a;
                (_a = audioRef === null || audioRef === void 0 ? void 0 : audioRef.current) === null || _a === void 0 ? void 0 : _a.play();
                setPlayed(true);
                setPaused(false);
                pausedRef.current = false;
            } }))) : (React.createElement(PauseWrap, { onClick: () => {
                var _a;
                (_a = audioRef === null || audioRef === void 0 ? void 0 : audioRef.current) === null || _a === void 0 ? void 0 : _a.pause();
                setPaused(true);
                pausedRef.current = true;
            } })),
        React.createElement(AudioDetails, { menuShow: !!(nav === null || nav === void 0 ? void 0 : nav.menuShow) },
            React.createElement(ClickSpan, { onClick: (e) => {
                    e.preventDefault();
                    changeNav && changeNav("voices");
                    if (pathname !== "/voices") {
                        history.push("/voices");
                    }
                    window.scrollTo(0, 0);
                } },
                React.createElement(Text, { size: "body", color: "white" }, "VOICES")),
            React.createElement(PlaySpan, { onClick: () => {
                    var _a, _b;
                    if (paused) {
                        (_a = audioRef === null || audioRef === void 0 ? void 0 : audioRef.current) === null || _a === void 0 ? void 0 : _a.play();
                        setPlayed(true);
                        setPaused(false);
                        pausedRef.current = false;
                    }
                    else {
                        (_b = audioRef === null || audioRef === void 0 ? void 0 : audioRef.current) === null || _b === void 0 ? void 0 : _b.pause();
                        setPaused(true);
                        pausedRef.current = true;
                    }
                } },
                React.createElement(Text, { size: "body", color: "white" }, `${paused ? "►" : "▐▐"} ${title}`)),
            React.createElement(Text, { size: "body", color: "white" }, `${time} / ${duration}`),
            linkText && linkUrl && (React.createElement("a", { href: linkUrl, target: "_blank" },
                React.createElement(Text, { size: "body", color: "white", underline: true }, linkText)))),
        React.createElement(Header, { menuShow: !!(nav === null || nav === void 0 ? void 0 : nav.menuShow) },
            React.createElement("div", null, img && React.createElement("img", { src: img })),
            React.createElement("div", null)),
        React.createElement(Waves, { paused: paused, pointer: !paused, onClick: (e) => {
                var _a;
                if (!paused) {
                    (_a = audioRef === null || audioRef === void 0 ? void 0 : audioRef.current) === null || _a === void 0 ? void 0 : _a.pause();
                    setPaused(true);
                    pausedRef.current = true;
                }
            } }),
        React.createElement(PlayerWrap, null,
            React.createElement(ProgressWrap, null,
                React.createElement(Draggable, { defaultPosition: { x: 0, y: 0 }, bounds: "parent", axis: "x", onStart: (e, data) => {
                        var _a;
                        if ((_a = progressRef === null || progressRef === void 0 ? void 0 : progressRef.current) === null || _a === void 0 ? void 0 : _a.offsetWidth) {
                            const player = audioRef.current;
                            const localPercentage = data.x / progressRef.current.offsetWidth;
                            if (player) {
                                player.currentTime = localPercentage * player.duration;
                            }
                        }
                        setShowTime(true);
                        e.stopPropagation();
                    }, onDrag: (e, data) => {
                        var _a;
                        if ((_a = progressRef === null || progressRef === void 0 ? void 0 : progressRef.current) === null || _a === void 0 ? void 0 : _a.offsetWidth) {
                            const player = audioRef.current;
                            const localPercentage = data.x / progressRef.current.offsetWidth;
                            if (player) {
                                player.currentTime = localPercentage * player.duration;
                            }
                        }
                        e.stopPropagation();
                    }, onStop: () => {
                        setShowTime(false);
                    } },
                    React.createElement(ProgressLine, { ref: progressDotRef, translateX: translateX, className: "swiper-no-swiping" })),
                showTime && played && (React.createElement(ProgressTime, { translateX: translateX, className: "swiper-no-swiping" },
                    React.createElement(Text, { size: "body", color: "white" }, time))),
                React.createElement(Progress, { ref: progressRef, value: "0", max: "1", paused: paused }))),
        src && (React.createElement("audio", { ref: audioRef },
            React.createElement("source", { src: src, type: "audio/mp3" })))));
};
const mapStateToProps = ({ nav, cart }) => {
    return { nav, cart };
};
export const AudioModule = connect(mapStateToProps, {
    changeNav: NavActions.changeNav
})(XAudioModule);
