import React from "react";
import { styled } from "linaria/react";
import MarqueeTag from "./MarqueeTag";
import { mq, PORTRAIT, LANDSCAPE } from "@styles";
import { Headline, Text } from "@common";
const MQ = mq;
const MarqueeContainer = styled.div `
  display: flex;
  flex-direction: row;
  height: 65px;
  justify-items: center;
  align-items: flex-start;
  padding: 20px 0 20px;
  position: absolute;
  background: ${(props) => (props.inverted ? "#000" : "#fff")};
  left: 0;
  width: 100vw;
  vertical-align: middle;
  z-index: 2;
  top: ${(props) => `${props.wrapHeight / 2 - 32.5}px`};
  @media (hover: none) and (pointer: coarse) {
    padding: 23px 0;
  }

  span + span {
    margin-left: 18px;
    &.text-body {
      line-height: 22px;
      vertical-align: super;
      @media (hover: none) and (pointer: coarse) {
        vertical-align: top;
      }
    }
  }

  a {
    color: black;
    &:visited {
      color: black;
    }
  }

  span {
    color: ${(props) => (props.inverted ? "#fff" : "#000")};
  }

  .text-small {
    bottom: 5px;
    position: relative;
  }

  .headline-2 {
    ${MQ.md} {
      font-size: 2.005rem;
      line-height: 2.005rem;
    }

    ${LANDSCAPE} {
      font-size: 2.005rem;
      line-height: 2.205rem;
    }

    ${MQ.sm} {
      font-size: 1.685rem;
      line-height: 1.885rem;
    }

    ${PORTRAIT} {
      font-size: 1.685rem;
      line-height: 1.885rem;
    }

    ${MQ.maxSm} {
      font-size: 2.005rem;
      line-height: 2.205rem;
    }
  }
`;
const Marquee = ({ wrapHeight, typeText, headlineText, ctaText, inverted, ctaUrl }) => {
    return (React.createElement(MarqueeContainer, { wrapHeight: wrapHeight, inverted: inverted },
        React.createElement(MarqueeTag
        // @ts-ignore
        , { 
            // @ts-ignore
            hoverToStop: true, loop: true },
            headlineText && React.createElement(Headline, { size: 2 }, headlineText),
            ctaText && ctaUrl && (React.createElement(Headline, { size: 2, underline: true },
                React.createElement("a", { href: ctaUrl, target: "_blank" }, ctaText))),
            typeText && React.createElement(Text, { size: "small" }, typeText),
            headlineText && React.createElement(Headline, { size: 2 }, headlineText),
            ctaText && ctaUrl && (React.createElement(Headline, { size: 2, underline: true },
                React.createElement("a", { href: ctaUrl, target: "_blank", style: { color: "black" } }, ctaText))),
            typeText && React.createElement(Text, { size: "small" }, typeText),
            headlineText && React.createElement(Headline, { size: 2 }, headlineText),
            ctaText && ctaUrl && (React.createElement(Headline, { size: 2, underline: true },
                React.createElement("a", { href: ctaUrl, target: "_blank", style: { color: "black" } }, ctaText))),
            typeText && React.createElement(Text, { size: "small" }, typeText),
            headlineText && React.createElement(Headline, { size: 2 }, headlineText),
            ctaText && ctaUrl && (React.createElement(Headline, { size: 2, underline: true },
                React.createElement("a", { href: ctaUrl, target: "_blank", style: { color: "black" } }, ctaText))),
            typeText && React.createElement(Text, { size: "small" }, typeText))));
};
export { Marquee };
