import React, { useState, useEffect } from "react";
import { styled } from "linaria/react";
import { AppContainer, QuoteModule, ContentModule } from "@components";
import { Frame, Text, Jumbo, Button, Headline } from "@common";
import { mq, LANDSCAPE, PORTRAIT } from "@styles";
import { useParams, useHistory } from "react-router";
import { connect } from "react-redux";
import { CartActions } from "@actions";
import ObjectHash from "object-hash";
const MQ = mq;
import Client from "shopify-buy";
const client = Client.buildClient({
    domain: "nomorerulers.myshopify.com",
    storefrontAccessToken: "79d98281b869d4d66d2f6148344847f4"
});
const Wrap = styled.div `
  position: relative;
  width: 100%;
  display: flex;

  ${MQ.sm} {
    flex-direction: column;
  }

  ${PORTRAIT} {
    flex-direction: column;
  }

  ${MQ.maxSm} {
    flex-direction: column;
  }
`;
const MobileAddCart = styled.div `
  display: none;
  background: #fff;
  position: fixed;
  width: calc(100vw - 48px);
  left: 24px;
  bottom: 24px;
  z-index: 2;

  a {
    background-color: #fff;
    &:first-child {
      flex: 1;
      text-align: center;
      border-right: 0;
    }
    &:last-child {
      text-align: center;
      width: 60px;

      ${MQ.sm} {
        width: 178px;
      }

      ${PORTRAIT} {
        width: 178px;
      }
    }
  }

  ${MQ.sm} {
    display: flex;
  }

  ${PORTRAIT} {
    display: flex;
  }

  ${MQ.maxSm} {
    display: flex;
  }
`;
const MobileVariantRow = styled.div `
  display: none;
  background: #fff;
  position: fixed;
  width: calc(100vw - 48px);
  left: 24px;
  bottom: 65px;
  z-index: 2;

  a {
    flex: 1;
    text-align: center;
    border-color: black !important;

    & + a {
      border-left: 0;
    }
  }

  ${MQ.sm} {
    display: flex;
  }

  ${PORTRAIT} {
    display: flex;
  }

  ${MQ.maxSm} {
    display: flex;
  }
`;
const MobileTitle = styled.div `
  display: none;
  background: #fff;

  ${MQ.sm} {
    display: block;
    padding: 78px 24px 24px;
  }

  ${PORTRAIT} {
    display: block;
    padding: 78px 24px 24px;
  }

  ${MQ.maxSm} {
    display: block;
    padding: 68px 24px 24px;
  }
`;
const Left = styled.div `
  width: calc(100vw / 2);
  height: 100vh;
  height: calc((var(--vh, 1vh) * 100));

  > div {
    padding: 24px 24px 0;
  }

  ${MQ.maxSm} {
    width: 100vw;
    > div {
      padding: 0;
    }
  }
`;
const Right = styled.div `
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  width: calc(100vw / 2);
  height: 100vh;
  height: calc((var(--vh, 1vh) * 100));
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 0 200px;

  ${MQ.md} {
    padding: 0 48px;
  }

  ${LANDSCAPE} {
    padding: 0 48px;
  }

  ${MQ.sm} {
    display: none;
  }

  ${PORTRAIT} {
    display: none;
  }

  ${MQ.maxSm} {
    display: none;
  }

  span + span {
    margin-top: 24px;
  }

  span + a {
    margin-top: 24px;
  }
`;
const InfoWrap = styled.div `
  position: fixed;
  bottom: 0;
  transform-origin: bottom left;
  left: 0;
  z-index: 2;
  height: ${(props) => (props.show ? "auto" : 0)};
  padding: ${(props) => (props.show ? "48px 24px" : 0)};
  background: white;
  transition: height 1s;
`;
const CloseInfo = styled.a `
  color: black;
  position: absolute;
  top: 17px;
  right: 24px;
  font-size: 28px;
  line-height: 28px;
  text-decoration: none;

  ${MQ.maxSm} {
    font-size: 21px;
    line-height: 21px;
  }
`;
const ButtonRow = styled.div `
  display: flex;
  margin: 24px 0;

  a {
    border-color: black !important;
  }

  a + a {
    margin-left: 6px;
  }
`;
const XProduct = (props) => {
    var _a;
    const history = useHistory();
    const params = useParams();
    const [cartItem, setCartItem] = useState(null);
    const [products, setProducts] = useState({});
    const [infoShown, setInfoShown] = useState(false);
    const [variantId, setVariantId] = useState("");
    const [variantTitle, setVariantTitle] = useState("");
    useEffect(() => {
        client.product.fetchAll(50).then((products) => {
            const beforeProducts = {};
            Object.values(products).forEach((productInfo) => {
                // @ts-ignore
                beforeProducts[productInfo.handle] = productInfo;
            });
            setProducts(beforeProducts);
        });
    }, []);
    useEffect(() => {
        var _a;
        if (!((_a = props.cart) === null || _a === void 0 ? void 0 : _a.checkoutId)) {
            client.checkout.create().then((checkout) => {
                // @ts-ignore
                props.createCheckout && props.createCheckout(checkout.id);
            });
        }
    }, [(_a = props.cart) === null || _a === void 0 ? void 0 : _a.checkoutId]);
    const product = products[params.handle];
    let desc = product ? product.descriptionHtml : "";
    const quote = /<strong>(.*?)<\/strong>/g.exec(desc);
    desc = quote ? desc.replace(quote[0], "") : desc;
    const createMarkup = () => {
        return {
            __html: product ? desc : ""
        };
    };
    useEffect(() => {
        if (product) {
            setCartItem({
                title: product.title,
                qty: 1,
                price: parseFloat(product.variants[0].price.amount),
                variantId: product.variants[0].id,
                variantTitle: product.variants[0].title,
                photo: product.images[0].src
            });
            setVariantId(product.variants[0].id);
        }
    }, [product && ObjectHash(product)]);
    const firstImage = product && product.images && product.images[0];
    const otherImages = (product && product.images) || [];
    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD"
    });
    return !product || Object.keys(product).length === 0 ? null : (React.createElement(AppContainer, null,
        React.createElement(Frame, null),
        React.createElement(Wrap, null,
            product.variants && product.variants.length > 1 && (React.createElement(MobileVariantRow, null, product.variants.map((variant) => {
                return (React.createElement(Button, { key: variant.id, isHeadline: false, bgColor: variant.id === variantId ? "black" : "white", color: variant.id === variantId ? "white" : "black", onClick: () => {
                        setVariantId(variant.id);
                        setVariantTitle(variant.title);
                    } }, variant.title));
            }))),
            React.createElement(MobileAddCart, null,
                product.variants && (React.createElement(Button, { isHeadline: true, href: "#", onClick: (e) => {
                        e.preventDefault();
                        if (cartItem) {
                            props.addCartItem && props.addCartItem(Object.assign(Object.assign({}, cartItem), { variantId, variantTitle }));
                        }
                    } },
                    formatter.format(product.variants[0].price.amount),
                    " - Add to Cart")),
                React.createElement(Button, { isHeadline: true, href: "#", onClick: (e) => {
                        e.preventDefault();
                        setInfoShown(!infoShown);
                    } }, "Info")),
            React.createElement(MobileTitle, null,
                React.createElement(Jumbo, { size: "text" }, product.title)),
            React.createElement(Left, null,
                firstImage && React.createElement(ContentModule, { half: true, bgImg: firstImage.src, mBgImg: firstImage.src }),
                quote && (React.createElement(QuoteModule, { half: true, bgColor: "red", quote: quote[0].replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, ""), caption: "" })),
                otherImages.map((img, index) => {
                    return index > 0 ? (React.createElement(ContentModule, { key: img.src, half: true, bgImg: img.src, mBgImg: img.src, bgColor: "white" })) : null;
                })),
            React.createElement(Right, null,
                React.createElement(Headline, { size: 1 }, product.title),
                React.createElement(Text, { size: "large", normalCase: true },
                    React.createElement("span", { dangerouslySetInnerHTML: createMarkup() })),
                product.variants && product.variants.length > 1 && (React.createElement(ButtonRow, null, product.variants.map((variant) => {
                    return (React.createElement(Button, { key: variant.id, isHeadline: false, bgColor: variant.id === variantId ? "black" : "white", color: variant.id === variantId ? "white" : "black", onClick: () => {
                            setVariantId(variant.id);
                            setVariantTitle(variant.title);
                        } }, variant.title));
                }))),
                product.variants && (React.createElement(Button, { isHeadline: true, href: "#", onClick: (e) => {
                        e.preventDefault();
                        if (cartItem) {
                            props.addCartItem && props.addCartItem(Object.assign(Object.assign({}, cartItem), { variantId, variantTitle }));
                        }
                    } },
                    formatter.format(product.variants[0].price.amount),
                    " - Add to Cart")))),
        React.createElement(InfoWrap, { show: infoShown },
            React.createElement(CloseInfo, { href: "#", onClick: (e) => {
                    e.preventDefault();
                    setInfoShown(!infoShown);
                } }, "\u00D7"),
            React.createElement(Text, { size: "large", normalCase: true },
                React.createElement("span", { dangerouslySetInnerHTML: createMarkup() })))));
};
const mapStateToProps = ({ cart }) => {
    return { cart };
};
export const Product = connect(mapStateToProps, {
    addCartItem: CartActions.addCartItem,
    createCheckout: CartActions.createCheckout
})(XProduct);
