import React from "react";
import { styled } from "linaria/react";
import { Text, Headline } from "@common";
import { mq } from "@styles";
const MQ = mq;
const ModuleWrap = styled.div `
  max-width: 670px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 100px;
  background: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  span + span {
    padding-top: 18px;
  }

  ${MQ.maxSm} {
    padding: 0 24px;
  }
`;
const MetaBlock = ({ body, title }) => {
    const createMarkup = () => {
        return {
            __html: body
        };
    };
    return (React.createElement(ModuleWrap, null,
        title && React.createElement(Headline, { size: 3 }, title),
        body && (React.createElement(Text, { size: "body", normalCase: true },
            React.createElement("span", { dangerouslySetInnerHTML: createMarkup() })))));
};
export { MetaBlock };
