import React, { useRef, useState, useEffect } from "react";
import { styled } from "linaria/react";
import { Text, Jumbo } from "@common";
import { mq } from "@styles";
const MQ = mq;
const Wrap = styled.div `
  max-width: 1440px;
  width: 100%;
  margin: 0 auto 100px;
  position: relative;

  video {
    max-width: 1440px;
    width: 100%;
  }
`;
const Overlay = styled.div `
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
  }
`;
const Title = styled.div `
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  padding-left: 140px;
  padding-right: 140px;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  span + span {
    margin-top: 16px;
  }

  ${MQ.maxSm} {
    padding-left: 24px;
    padding-right: 24px;
    span.jumbo-text {
      font-size: 2rem;
      line-height: 2rem;
      letter-spacing: -0.02rem;
    }
  }
`;
const ArticleVideo = ({ src, posterImg, title }) => {
    const [paused, setPaused] = useState(true);
    const videoRef = useRef(null);
    useEffect(() => {
        if (!paused) {
            setTimeout(() => {
                var _a;
                (_a = videoRef.current) === null || _a === void 0 ? void 0 : _a.play();
            }, 200);
        }
    }, [paused]);
    return (React.createElement(Wrap, null, paused ? (React.createElement(Overlay, null,
        React.createElement(Title, { onClick: () => {
                setPaused(false);
            } },
            React.createElement(Jumbo, { size: "text", color: "white" }, `► ${title}`),
            React.createElement(Text, { size: "body", color: "white" }, "Play Video")),
        posterImg && React.createElement("img", { src: posterImg }))) : (src && (React.createElement("video", { src: src, controls: true, preload: "none", ref: videoRef, playsInline: true, autoPlay: true, poster: posterImg || undefined })))));
};
export { ArticleVideo };
