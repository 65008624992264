import React from "react";
import { styled } from "linaria/react";
import { mq, PORTRAIT, LANDSCAPE } from "@styles";
const MQ = mq;
const Book3dContainer = styled.div `
  @-webkit-keyframes rotatingAnimationX {
    0% {
      -webkit-transform: rotateX(0deg);
      transform: rotateX(0deg);
    }
    100% {
      -webkit-transform: rotateX(360deg);
      transform: rotateX(360deg);
    }
  }
  @keyframes rotatingAnimationX {
    0% {
      -webkit-transform: rotateX(0deg);
      transform: rotateX(0deg);
    }
    100% {
      -webkit-transform: rotateX(360deg);
      transform: rotateX(360deg);
    }
  }
  @-webkit-keyframes rotatingAnimationY {
    0% {
      -webkit-transform: rotateY(0deg);
      transform: rotateY(0deg);
    }
    100% {
      -webkit-transform: rotateY(360deg);
      transform: rotateY(360deg);
    }
  }
  @keyframes rotatingAnimationY {
    0% {
      -webkit-transform: rotateY(0deg);
      transform: rotateY(0deg);
    }
    100% {
      -webkit-transform: rotateY(360deg);
      transform: rotateY(360deg);
    }
  }
  @-webkit-keyframes rotatingAnimationZ {
    0% {
      -webkit-transform: rotateZ(0deg);
      transform: rotateZ(0deg);
    }
    100% {
      -webkit-transform: rotateZ(360deg);
      transform: rotateZ(360deg);
    }
  }
  @keyframes rotatingAnimationZ {
    0% {
      -webkit-transform: rotateZ(0deg);
      transform: rotateZ(0deg);
    }
    100% {
      -webkit-transform: rotateZ(360deg);
      transform: rotateZ(360deg);
    }
  }

  flex: 1;
  width: calc(100vw / 2);
  height: 100%;
  -webkit-perspective: 1200px;
  perspective: 1200px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: ${(props) => (props.isAbsolute ? "absolute" : "relative")};
  right: 0;
  height: ${(props) => (props.isPdp ? "400px" : "auto")};
  min-height: ${(props) => (props.isPdp ? "400px" : "auto")};
  transform: ${(props) => (props.isPdp ? "scale(0.65)" : "none")};

  ${MQ.macbook} {
    transform: scale(0.65);
  }

  ${MQ.md} {
    transform: scale(0.85);
  }
  ${LANDSCAPE} {
    transform: scale(0.85);
  }

  ${MQ.sm} {
    transform: scale(0.65);
  }
  ${PORTRAIT} {
    transform: scale(0.65);
  }

  ${MQ.maxSm} {
    width: 100vw;
    transform: scale(0.65);
    top: 0;
    height: ${(props) => (props.isPdp ? "400px" : "550px")};
    min-height: ${(props) => (props.isPdp ? "400px" : "550px")};
    position: relative;
  }

  & > div {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }
  .boockup {
    -webkit-transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  }
  .book-container {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-animation: 14s rotatingAnimationY linear infinite;
    animation: 14s rotatingAnimationY linear infinite;
    animation-play-state: ${(props) => (props.focused ? "running" : "paused")};
  }
  .book-container > div {
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center center;
    -webkit-backface-visibility: visible;
    backface-visibility: visible;
  }
  .book-top,
  .book-bottom,
  .book-side-right {
    background-size: auto auto;
    background-repeat: repeat;
    background-position: top left;
  }
  .book-front {
    background-image: ${(props) => props.cover};
    width: 396px;
    height: 492px;
    -webkit-transform: translate3d(-198px, -246px, 24px) translate3d(0, 0, 0) rotateY(0deg);
    transform: translate3d(-198px, -246px, 24px) translate3d(0, 0, 0) rotateY(0deg);
  }
  .book-side-left {
    background-image: ${(props) => props.spine};
    width: 48px;
    height: 492px;
    -webkit-transform: translate3d(-198px, -246px, 24px) translate3d(-24px, 0, -24px) rotateY(-90deg);
    transform: translate3d(-198px, -246px, 24px) translate3d(-24px, 0, -24px) rotateY(-90deg);
  }
  .book-side-right {
    background-image: url("https://nmr-assets.s3.amazonaws.com/books/book-side.jpg");
    width: 48px;
    height: 492px;
    -webkit-transform: translate3d(-198px, -246px, 24px) translate3d(372px, 0px, -24px) rotateY(90deg);
    transform: translate3d(-198px, -246px, 24px) translate3d(372px, 0px, -24px) rotateY(90deg);
  }
  .book-top {
    background-image: url("https://nmr-assets.s3.amazonaws.com/books/book-top.jpg");
    background-position: bottom right;
    width: 396px;
    height: 48px;
    -webkit-transform: translate3d(-198px, -246px, 24px) translate3d(0px, -24px, -24px) rotateX(90deg);
    transform: translate3d(-198px, -246px, 24px) translate3d(0px, -24px, -24px) rotateX(90deg);
  }
  .book-bottom {
    background-image: url("https://nmr-assets.s3.amazonaws.com/books/book-top.jpg");
    width: 396px;
    height: 48px;
    -webkit-transform: translate3d(-198px, -246px, 24px) translate3d(0px, 468px, -24px) rotateX(-90deg);
    transform: translate3d(-198px, -246px, 24px) translate3d(0px, 468px, -24px) rotateX(-90deg);
  }
  .book-back {
    background-image: ${(props) => props.back};
    width: 396px;
    height: 492px;
    -webkit-transform: translate3d(-198px, -246px, 24px) translate3d(0, 0, -48px) rotateY(180deg);
    transform: translate3d(-198px, -246px, 24px) translate3d(0, 0, -48px) rotateY(180deg);
  }
`;
export const Book3d = (props) => {
    const back = `url("https://nmr-assets.s3.amazonaws.com/books/${props.artist}-book-back.jpg?v=2.0.0")`;
    const cover = `url("https://nmr-assets.s3.amazonaws.com/books/${props.artist}-book-cover.jpg?v=2.0.0")`;
    const spine = `url("https://nmr-assets.s3.amazonaws.com/books/${props.artist}-book-spine.jpg?v=2.0.0")`;
    return (React.createElement(Book3dContainer, { className: "book-3d", spine: spine, cover: cover, back: back, focused: props.focused, isPdp: !!props.isPdp, isAbsolute: !!props.isAbsolute },
        React.createElement("div", { className: "boockup" },
            React.createElement("div", { className: "book-container" },
                React.createElement("div", { className: "book-front" }),
                React.createElement("div", { className: "book-side-left" }),
                React.createElement("div", { className: "book-side-right" }),
                React.createElement("div", { className: "book-top" }),
                React.createElement("div", { className: "book-bottom" }),
                React.createElement("div", { className: "book-back" })))));
};
