import React, { useState } from "react";
import { styled } from "linaria/react";
import { Text, Jumbo } from "@common";
import { mq } from "@styles";
import { CopyToClipboard } from "react-copy-to-clipboard";
const MQ = mq;
const ModuleWrap = styled.div `
  position: sticky;
  top: 0;
  z-index: 0;
  width: 100vw;
  background: #fff;
  padding: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  span {
    text-align: center;
  }

  span.jumbo-headline {
    line-height: 90%;
  }

  span + span {
    margin-top: 40px;
  }

  span + span:nth-child(2) {
    margin-top: 0;
  }

  ${MQ.maxSm} {
    padding: 80px 24px;

    span.jumbo-headline {
      font-size: 4.25rem;
    }
  }
`;
const Share = styled.div `
  background-image: url(https://nmr-frontend.s3.amazonaws.com/icons/share-icon.svg);
  background-size: 12px 12px;
  background-position: 10px center;
  background-repeat: no-repeat;
  height: 25px;
  border-radius: 12.5px;
  padding: 3px 16px 4px 28px;
  border: 1px solid black;
  cursor: pointer;
  span {
    cursor: pointer;
  }
  &:hover {
    background-color: black;
    span {
      color: white;
    }
    background-image: url(https://nmr-frontend.s3.amazonaws.com/icons/anchor-icon-white.svg);
  }
`;
const CaptionWrap = styled.div `
  margin-bottom: 40px;
  ${MQ.maxSm} {
    margin-bottom: 20px;
  }
`;
const ArticleTitle = ({ mainTitle, subTitle, author, hasImage }) => {
    const tags = [
        {
            label: "Futura"
        },
        {
            label: "Exhibition"
        },
        {
            label: "ISMS"
        },
        {
            label: "Events"
        }
    ];
    const [shareText, setShareText] = useState("Share");
    return (React.createElement(ModuleWrap, { hasImage: hasImage },
        mainTitle && React.createElement(Jumbo, { size: "headline" }, mainTitle),
        subTitle && (React.createElement(Jumbo, { size: "headline", stroke: true, color: "white" }, subTitle)),
        author && (React.createElement(CaptionWrap, null,
            React.createElement(Text, { size: "body" }, author))),
        React.createElement(CopyToClipboard, { text: window.location.href, onCopy: () => {
                setShareText("Copied!");
                setTimeout(() => {
                    setShareText("Share");
                }, 2500);
            } },
            React.createElement(Share, null,
                React.createElement(Text, { size: "small" }, shareText)))));
};
export { ArticleTitle };
