import React from "react";
import { styled } from "linaria/react";
import { Colors } from "@styles";
import { Text } from "@common";
const TagWrap = styled.div `
  display: flex;
  flex-direction: row;
  margin-top: 36px;

  > div {
    margin-left: 4px;
  }
`;
const Tag = styled.div `
  height: 20px;
  padding: 4px 8px;
  background-color: ${Colors.lightGrey};
  border-radius: 10px;
  line-height: 11px;
`;
const Tags = ({ tags }) => {
    return (React.createElement(TagWrap, null, tags.map((tag) => {
        return (React.createElement(Tag, { key: tag.label },
            React.createElement(Text, { size: "small" }, tag.label)));
    })));
};
export { Tags };
