import React from "react";
import { styled } from "linaria/react";
import { Text, Headline } from "@common";
import ReactMarkdown from "react-markdown";
import { mq } from "@styles";
import rehypeRaw from "rehype-raw";
const MQ = mq;
const ModuleWrap = styled.div `
  max-width: 670px;
  width: 100%;
  margin: 0 auto 100px;
  background: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  span + span {
    padding-top: 18px;
  }

  .headline-4 {
    text-align: center;
    align-self: center;
  }

  ${MQ.maxSm} {
    padding: 0 24px;

    iframe {
      width: 100%;
      aspect-ratio: 16 / 9;
    }
  }
`;
const ArticleBody = ({ body, subtitle }) => {
    return (React.createElement(ModuleWrap, null,
        subtitle && React.createElement(Headline, { size: 4 }, subtitle),
        body && (React.createElement(Text, { size: "article", normalCase: true, light: true },
            React.createElement(ReactMarkdown, { rehypePlugins: [rehypeRaw] }, body)))));
};
export { ArticleBody };
