import React from "react";
import { styled } from "linaria/react";
import { mq } from "@styles";
import { NavActions } from "@actions";
import { connect } from "react-redux";
const MQ = mq;
const ModuleWrap = styled.div `
  width: 100vw;
  padding: 0;

  @media (min-width: 1100px) {
    padding: 0 48px;
  }
`;
const XSoundCloudModule = ({ embedCode, status }) => {
    const createMarkup = () => {
        return {
            __html: embedCode || ""
        };
    };
    return embedCode && status === "active" ? React.createElement(ModuleWrap, { dangerouslySetInnerHTML: createMarkup() }) : null;
};
const mapStateToProps = ({ nav, cart }) => {
    return { nav, cart };
};
export const SoundCloudModule = connect(mapStateToProps, {
    changeNav: NavActions.changeNav
})(XSoundCloudModule);
