import React from "react";
import { Colors } from "@styles";
import { styled } from "linaria/react";
import { Text, Button, Cube } from "@common";
import { mq } from "@styles";
const MQ = mq;
const ModuleWrap = styled.div `
  width: 100vw;
  background: ${(props) => (props.bgColor ? Colors[props.bgColor] : ``)};
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: ${(props) => (props.bgColor && props.imgHeight ? `${props.imgHeight + 192}px` : "auto")};
  padding: 64px 0;

  ${MQ.maxSm} {
    flex-direction: column;
    height: auto;
    padding: 80px 0;
  }
`;
const CardWrap = styled.div `
  width: calc(100vw / 2);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  ${MQ.maxSm} {
    width: calc(100vw - 52px);
    margin-bottom: 60px;
  }
`;
const SocialWrap = styled.div `
  display: flex;
  flex-direction: row;
  width: 360px;
`;
const SocialHeader = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  > div {
    display: flex;
    flex-direction: column;
    height: 31px;
    justify-content: center;
  }
`;
const SocialLogo = styled.div `
  display: flex;
  margin-right: 20px;
  min-width: 50px;
  height: 50px;
  background: url("https://nmr-frontend.s3.amazonaws.com/logos/logo-instagram.svg") center center no-repeat;
  background-size: contain;
  filter: invert(1);

  ${MQ.maxSm} {
    margin-right: 8px;
  }
`;
const Caption = styled.div `
  margin-top: 30px;
`;
const SingleImage = styled.img `
  width: 342px;
  height: 427px;
  margin: 80px auto;

  ${MQ.maxSm} {
    width: 100vw;
    height: calc(100vw * (5 / 4));
    margin: 0 auto 80px;
  }
`;
const SocialModule = ({ caption, followerCount, images }) => {
    // @ts-ignore
    const cubeImages = Array(4)
        .fill(images)
        .reduce((acc, val) => acc.concat(val), []);
    return (React.createElement(ModuleWrap, { bgColor: "black" },
        React.createElement(Cube, { images: cubeImages }),
        React.createElement(CardWrap, null,
            React.createElement(SocialWrap, null,
                React.createElement(SocialLogo, null),
                React.createElement("div", null,
                    React.createElement(SocialHeader, null,
                        React.createElement("div", null,
                            React.createElement(Text, { size: "body", color: "white" }, "@NOMORERULERS")),
                        React.createElement(Button, { color: "white", hoverBg: "black", href: "https://www.instagram.com/nomorerulers/", openNewTab: true }, "View Profile")),
                    caption && (React.createElement(Caption, null,
                        React.createElement(Text, { size: "body", color: "white" }, caption))))))));
};
export { SocialModule };
