import React from "react";
import { styled } from "linaria/react";
import { Colors } from "@styles";
import { mq, PORTRAIT } from "@styles";
const MQ = mq;
const TopBar = styled.div `
  mix-blend-mode: difference;
  height: 2px;
  width: ${(props) => (props.half ? "calc(50vw - 48px)" : "calc(100vw - 48px)")};
  left: 24px;
  top: 24px;
  position: absolute;
  z-index: 2;
  background-color: ${(props) => Colors[props.color]};

  ${MQ.sm} {
    display: none;
  }

  ${PORTRAIT} {
    display: none;
  }

  ${MQ.maxSm} {
    display: none;
  }
`;
const RightBar = styled.div `
  mix-blend-mode: difference;
  width: 2px;
  height: calc(100% - 48px);
  right: 24px;
  top: 24px;
  position: absolute;
  z-index: 2;
  background-color: ${(props) => Colors[props.color]};

  ${MQ.sm} {
    display: none;
  }

  ${PORTRAIT} {
    display: none;
  }

  ${MQ.maxSm} {
    display: none;
  }
`;
const BottomBar = styled.div `
  mix-blend-mode: difference;
  height: 2px;
  width: ${(props) => (props.half ? "calc(50vw - 48px)" : "calc(100vw - 48px)")};
  left: 24px;
  bottom: 24px;
  position: absolute;
  z-index: 2;
  background-color: ${(props) => Colors[props.color]};

  ${MQ.sm} {
    display: none;
  }

  ${PORTRAIT} {
    display: none;
  }

  ${MQ.maxSm} {
    display: none;
  }
`;
const LeftBar = styled.div `
  mix-blend-mode: difference;
  width: 2px;
  height: calc(100% - 48px);
  left: 24px;
  top: 24px;
  position: absolute;
  z-index: 2;
  background-color: ${(props) => Colors[props.color]};

  ${MQ.sm} {
    display: none;
  }

  ${PORTRAIT} {
    display: none;
  }

  ${MQ.maxSm} {
    display: none;
  }
`;
const MiniFrame = ({ color, half }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(TopBar, { color: "white", half: half }),
        React.createElement(RightBar, { color: "white" }),
        React.createElement(BottomBar, { color: "white", half: half }),
        React.createElement(LeftBar, { color: "white" })));
};
export { MiniFrame };
