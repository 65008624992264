import React from "react";
import { styled } from "linaria/react";
import { Text } from "@common";
import { mq, PORTRAIT, LANDSCAPE } from "@styles";
import { NavActions } from "@actions";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router";
const MQ = mq;
const ModuleWrap = styled.div `
  width: 100vw;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding: 54px 70px 53px;

  a {
    text-decoration: none;
  }

  ${MQ.md} {
    padding: 54px 168px;
    flex-direction: column;
  }
  ${LANDSCAPE} {
    padding: 54px 168px;
    flex-direction: column;
  }

  ${MQ.sm} {
    flex-direction: column;
    padding: 45px 48px;

    a + a,
    a + span {
      margin-top: 8px;
    }
  }
  ${PORTRAIT} {
    flex-direction: column;
    padding: 45px 48px;

    a + a,
    a + span {
      margin-top: 8px;
    }
  }

  ${MQ.maxSm} {
    flex-direction: column;
    padding: 45px 27px;

    a + a,
    a + span {
      margin-top: 8px;
    }
  }
`;
const Wrap = styled.div `
  display: flex;
  width: 100%;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  ${MQ.md} {
    display: none;
  }

  ${LANDSCAPE} {
    display: none;
  }

  ${MQ.sm} {
    display: none;
  }

  ${PORTRAIT} {
    display: none;
  }

  ${MQ.maxSm} {
    display: none;
  }
`;
const TouchWrap = styled.div `
  display: none;
  margin-top: 0;
  flex-direction: column;
  width: 100%;

  ${MQ.md} {
    display: flex;
  }

  ${LANDSCAPE} {
    display: flex;
  }

  ${MQ.sm} {
    display: flex;
  }

  ${PORTRAIT} {
    display: flex;
  }

  ${MQ.maxSm} {
    display: flex;
  }
`;
const FooterRow = styled.div `
  ${MQ.md} {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    &:last-child {
      margin-top: 12px;
    }
  }
  ${LANDSCAPE} {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    &:last-child {
      margin-top: 12px;
    }
  }

  ${MQ.sm} {
    display: flex;
    flex-direction: column;
    & + div {
      margin-top: 8px;
    }
  }

  ${PORTRAIT} {
    display: flex;
    flex-direction: column;
    & + div {
      margin-top: 8px;
    }
  }

  ${MQ.maxSm} {
    display: flex;
    flex-direction: column;
    & + div {
      margin-top: 8px;
    }
  }
`;
const FooterNavWrap = styled.div `
  margin-top: 64px;
  width: 100%;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;

  ${MQ.md} {
    margin-top: 0;
    flex-direction: column;
  }

  ${LANDSCAPE} {
    margin-top: 0;
    flex-direction: column;
  }

  ${MQ.sm} {
    margin-top: 0;
    flex-direction: column;
  }

  ${PORTRAIT} {
    margin-top: 0;
    flex-direction: column;
  }

  ${MQ.maxSm} {
    margin-top: 0;
    flex-direction: column;
  }
`;
const XFooter = (props) => {
    const history = useHistory();
    const pathname = useLocation().pathname.toLowerCase();
    const year = new Date().getFullYear();
    return (React.createElement(ModuleWrap, null,
        React.createElement(FooterNavWrap, null,
            React.createElement(Wrap, null,
                React.createElement("a", { href: "/about" },
                    React.createElement(Text, { size: "body" }, "About Us")),
                React.createElement("a", { href: "mailto: info@nomorerulers.com" },
                    React.createElement(Text, { size: "body" }, "Contact")),
                React.createElement("a", { href: "https://mailchi.mp/4569d98cb3a3/no-more-rulers-newsletter-001" },
                    React.createElement(Text, { size: "body" }, "Newsletter")),
                React.createElement("a", { href: "https://www.instagram.com/nomorerulers/", target: "_blank" },
                    React.createElement(Text, { size: "body" }, "Instagram")),
                React.createElement("a", { href: "/shipping-returns" },
                    React.createElement(Text, { size: "body" }, "Shipping & Returns")),
                React.createElement("a", { href: "/privacy-policy" },
                    React.createElement(Text, { size: "body" }, "Privacy Policies")),
                React.createElement("a", { href: "/terms-of-use" },
                    React.createElement(Text, { size: "body" }, "Terms of use")),
                React.createElement(Text, { size: "body" }, "\u00A9 no more rulers, 2021.")),
            React.createElement(TouchWrap, null,
                React.createElement(FooterRow, null,
                    React.createElement("a", { href: "/about" },
                        React.createElement(Text, { size: "body" }, "About Us")),
                    React.createElement("a", { href: "mailto: info@nomorerulers.com" },
                        React.createElement(Text, { size: "body" }, "Contact")),
                    React.createElement("a", { href: "https://mailchi.mp/4569d98cb3a3/no-more-rulers-newsletter-001" },
                        React.createElement(Text, { size: "body" }, "Newsletter")),
                    React.createElement("a", { href: "https://www.instagram.com/nomorerulers/", target: "_blank" },
                        React.createElement(Text, { size: "body" }, "Instagram"))),
                React.createElement(FooterRow, null,
                    React.createElement("a", { href: "/shipping-returns" },
                        React.createElement(Text, { size: "body" }, "Shipping & Returns")),
                    React.createElement("a", { href: "/privacy-policy" },
                        React.createElement(Text, { size: "body" }, "Privacy Policies")),
                    React.createElement("a", { href: "/terms-of-use" },
                        React.createElement(Text, { size: "body" }, "Terms of use")),
                    React.createElement(Text, { size: "body" }, `© no more rulers, ${year}.`))))));
};
const mapStateToProps = ({ nav, cart }) => {
    return { nav };
};
export const Footer = connect(mapStateToProps, {
    changeNav: NavActions.changeNav
})(XFooter);
