import React, { useRef } from "react";
import { styled } from "linaria/react";
import { Text } from "@common";
import { mq } from "@styles";
// @ts-ignore
import useDragScroll from "use-drag-scroll";
const MQ = mq;
const ScrollWrap = styled.div `
  height: 620px;
  margin: 0 auto 100px;
  overflow-x: scroll;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: row;
  padding: 0 26px;
  cursor: move;
  cursor: grab;

  ${MQ.maxSm} {
    height: 350px;
    padding: 0;
  }
`;
const ImageItem = styled.div `
  img + span {
    margin-top: 8px;
  }

  display: flex;
  flex-direction: column;
  img {
    max-height: 600px;
  }

  ${MQ.maxSm} {
    img {
      max-height: 300px;
    }
    div {
      padding: 0 16px;
      span {
        letter-spacing: 0.25px;
      }
    }
  }
`;
const ArticleGallery = ({ images }) => {
    const ref = useRef(null);
    useDragScroll({
        sliderRef: ref,
        momentumVelocity: 0.95
    });
    return (React.createElement(ScrollWrap, { ref: ref }, images &&
        images.map((image) => {
            return (React.createElement(ImageItem, { key: image.url },
                React.createElement("img", { src: image.url }),
                React.createElement("div", null, image.caption && React.createElement(Text, { size: "small" }, image.caption))));
        })));
};
export { ArticleGallery };
