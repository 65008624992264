var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useMemo } from "react";
import { styled } from "linaria/react";
import { Colors } from "@styles";
import { jumboUltra, jumboHeadline, jumboMax, jumboText, headline1, headline2, headline3, headline4, headline5, headline6, bodyText, largeText, smallText, detailText, articleText, articleLightText, jumboNewsletter } from "@styles";
const Headline = (_a) => {
    var { children, size, color, underline } = _a, props = __rest(_a, ["children", "size", "color", "underline"]);
    const StyledSpan = useMemo(() => {
        switch (size) {
            case 1:
                return styled.span `
          ${headline1}
          text-transform: uppercase;
          color: ${() => (color ? Colors[color] : "#000")};
          text-decoration: ${() => (underline ? "underline" : "none")};
        `;
            case 2:
                return styled.span `
          ${headline2}
          text-transform: uppercase;
          color: ${() => (color ? Colors[color] : "#000")};
          text-decoration: ${() => (underline ? "underline" : "none")};
        `;
            case 3:
                return styled.span `
          ${headline3}
          text-transform: uppercase;
          color: ${() => (color ? Colors[color] : "#000")};
          text-decoration: ${() => (underline ? "underline" : "none")};
        `;
            case 4:
                return styled.span `
          ${headline4}
          text-transform: uppercase;
          color: ${() => (color ? Colors[color] : "#000")};
          text-decoration: ${() => (underline ? "underline" : "none")};
        `;
            case 5:
                return styled.span `
          ${headline5}
          text-transform: uppercase;
          color: ${() => (color ? Colors[color] : "#000")};
          text-decoration: ${() => (underline ? "underline" : "none")};
        `;
            case 6:
                return styled.span `
          ${headline6}
          text-transform: uppercase;
          color: ${() => (color ? Colors[color] : "#000")};
          text-decoration: ${() => (underline ? "underline" : "none")};
        `;
        }
    }, [children, size, color]);
    return (StyledSpan && (React.createElement(StyledSpan, Object.assign({}, props, { className: `headline-${size}` }), children)));
};
const Jumbo = (_a) => {
    var { children, size, color, stroke, invert } = _a, props = __rest(_a, ["children", "size", "color", "stroke", "invert"]);
    const StyledSpan = useMemo(() => {
        switch (size) {
            case "newsletter":
                return styled.span `
          ${jumboNewsletter}
          color: ${() => (color ? Colors[color] : "#000")};
          -webkit-text-stroke: ${() => (stroke ? (invert ? "1px white" : "1px black") : "none")};
          white-space: pre-wrap;
        `;
            case "headline":
                return styled.span `
          ${jumboHeadline}
          color: ${() => (color ? Colors[color] : "#000")};
          -webkit-text-stroke: ${() => (stroke ? (invert ? "1px white" : "1px black") : "none")};
          white-space: pre-wrap;
        `;
            case "ultra":
                return styled.span `
          ${jumboUltra}
          color: ${() => (color ? Colors[color] : "#000")};
          -webkit-text-stroke: ${() => (stroke ? (invert ? "1px white" : "1px black") : "none")};
          white-space: pre-wrap;
        `;
            case "max":
                return styled.span `
          ${jumboMax}
          color: ${() => (color ? Colors[color] : "#000")};
          -webkit-text-stroke: ${() => (stroke ? (invert ? "1px white" : "1px black") : "none")};
          white-space: pre-wrap;
        `;
            case "text":
                return styled.span `
          ${jumboText}
          color: ${() => (color ? Colors[color] : "#000")};
          -webkit-text-stroke: ${() => (stroke ? (invert ? "1px white" : "1px black") : "none")};
          white-space: pre-wrap;
        `;
        }
    }, [children, size, color]);
    return (StyledSpan && (React.createElement(StyledSpan, Object.assign({}, props, { className: `jumbo-${size}` }), children)));
};
const Text = (_a) => {
    var { children, size, color, underline, normalCase, light } = _a, props = __rest(_a, ["children", "size", "color", "underline", "normalCase", "light"]);
    const StyledSpan = useMemo(() => {
        switch (size) {
            case "article":
                if (light) {
                    return styled.span `
            ${articleLightText}
            color: ${() => (color ? Colors[color] : "#000")};
            text-decoration: ${() => (underline ? "underline" : "none")};
            text-transform: ${() => (normalCase ? "normal" : "uppercase")};
          `;
                }
                return styled.span `
          ${articleText}
          color: ${() => (color ? Colors[color] : "#000")};
          text-decoration: ${() => (underline ? "underline" : "none")};
          text-transform: ${() => (normalCase ? "normal" : "uppercase")};
        `;
            case "large":
                return styled.span `
          ${largeText}
          color: ${() => (color ? Colors[color] : "#000")};
          text-decoration: ${() => (underline ? "underline" : "none")};
          text-transform: ${() => (normalCase ? "normal" : "uppercase")};
        `;
            case "body":
                return styled.span `
          ${bodyText}
          color: ${() => (color ? Colors[color] : "#000")};
          text-decoration: ${() => (underline ? "underline" : "none")};
          text-transform: ${() => (normalCase ? "normal" : "uppercase")};
        `;
            case "small":
                return styled.span `
          ${smallText}
          color: ${() => (color ? Colors[color] : "#000")};
          text-decoration: ${() => (underline ? "underline" : "none")};
          text-transform: ${() => (normalCase ? "normal" : "uppercase")};
        `;
            case "detail":
                return styled.span `
          ${detailText}
          color: ${() => (color ? Colors[color] : "#000")};
          text-decoration: ${() => (underline ? "underline" : "none")};
          text-transform: ${() => (normalCase ? "normal" : "uppercase")};
        `;
        }
    }, [children, size, color, normalCase]);
    return (StyledSpan && (React.createElement(StyledSpan, Object.assign({}, props, { className: `text-${size}` }), children)));
};
export { Headline, Text, Jumbo };
