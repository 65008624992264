import React from "react";
import { styled } from "linaria/react";
import { mq } from "@styles";
const MQ = mq;
const CubeWrap = styled.div `
  perspective: 800px;
  width: 342px;
  height: 427px;
  margin: 80px auto;
  .cube {
    height: 100%;
    width: 100%;
    position: relative;
    transform-style: preserve-3d;
    transition: all 0.8s ease-in-out;
    animation: rotate 16s infinite;
  }

  .cube img {
    position: absolute;
    width: 342px;
    height: 427px;
    backface-visibility: hidden;
  }

  ${MQ.maxSm} {
    width: 100vw;
    height: calc(100vw * (5 / 4));
    transform: scale(0.77);

    .cube img {
      position: absolute;
      width: 100vw;
      height: calc(100vw * (5 / 4));
      backface-visibility: hidden;
    }
  }

  .cube img:nth-child(1) {
    transform: rotateY(0deg) translateZ(171px);
  }
  .cube img:nth-child(2) {
    transform: rotateY(90deg) translateZ(171px);
  }
  .cube img:nth-child(3) {
    transform: rotateY(-90deg) translateZ(171px);
  }
  .cube img:nth-child(4) {
    transform: rotateY(180deg) translateZ(171px);
  }

  ${MQ.maxSm} {
    .cube img:nth-child(1) {
      transform: rotateY(0deg) translateZ(calc(100vw / 2));
    }
    .cube img:nth-child(2) {
      transform: rotateY(90deg) translateZ(calc(100vw / 2));
    }
    .cube img:nth-child(3) {
      transform: rotateY(-90deg) translateZ(calc(100vw / 2));
    }
    .cube img:nth-child(4) {
      transform: rotateY(180deg) translateZ(calc(100vw / 2));
    }
  }

  @keyframes rotate {
    0% {
      transform: rotateY(0deg);
    }
    25% {
      transform: rotateY(90deg);
    }
    50% {
      transform: rotateY(180deg);
    }
    75% {
      transform: rotateY(270deg);
    }
    100% {
      transform: rotateY(360deg);
    }
  }

  @keyframes rotateShadow {
    0% {
      transform: rotateX(95deg) translateZ(-80px) scale(1) rotateZ(360deg);
    }
    25% {
      transform: rotateX(95deg) translateZ(-80px) scale(1) rotateZ(270deg);
    }
    50% {
      transform: rotateX(95deg) translateZ(-80px) scale(1) rotateZ(180deg);
    }
    75% {
      transform: rotateX(95deg) translateZ(-80px) scale(1) rotateZ(90deg);
    }
    100% {
      transform: rotateX(95deg) translateZ(-80px) scale(1) rotateZ(0deg);
    }
  }
`;
export const Cube = ({ images }) => {
    return (React.createElement(CubeWrap, null,
        React.createElement("div", { className: "cube" }, images.map((src, index) => (React.createElement("img", { src: src, key: [src, index].join("-") }))))));
};
