import React, { useState } from "react";
import { Colors } from "@styles";
import { styled } from "linaria/react";
import { Headline, Text, Button, Book3d } from "@common";
import { mq, PORTRAIT, LANDSCAPE } from "@styles";
import { useWindowSize } from "@utils";
import { NavActions } from "@actions";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router";
const MQ = mq;
const ModuleWrap = styled.div `
  width: 100vw;
  background: ${(props) => props.bgColor ? (props.bgColor === "charcoal" ? Colors.charcoalBg : Colors[props.bgColor]) : ``};
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: ${(props) => props.bgColor && props.imgHeight ? `${props.imgHeight + 192}px` : props.bgImg ? "auto" : "800px"};

  img.bg-img {
    width: 100%;
  }
  ${MQ.md} {
    height: ${(props) => props.bgColor && props.imgHeight ? `${props.imgHeight + 192}px` : props.bgImg ? "auto" : "600px"};
  }
  ${LANDSCAPE} {
    height: ${(props) => props.bgColor && props.imgHeight ? `${props.imgHeight + 192}px` : props.bgImg ? "auto" : "600px"};
  }
  ${MQ.sm} {
    height: ${(props) => props.bgColor && props.imgHeight ? `${props.imgHeight + 192}px` : props.bgImg ? "auto" : "500px"};
  }
  ${PORTRAIT} {
    height: ${(props) => props.bgColor && props.imgHeight ? `${props.imgHeight + 192}px` : props.bgImg ? "auto" : "800px"};
  }

  ${MQ.maxSm} {
    flex-direction: ${(props) => (props.isBook ? "column" : "column-reverse")};
    height: auto;
    img.bg-img {
      position: absolute;
    }
  }
`;
const CardWrap = styled.div `
  width: calc(100vw / 2);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: ${(props) => (props.reverse ? "auto" : 0)};
  right: ${(props) => (props.reverse ? 0 : "auto")};

  ${MQ.maxSm} {
    width: calc(100vw - 42px);
    margin-bottom: 21px;
    position: relative;
    top: auto;
    right: auto;
    transform: none;
  }
`;
const ImgWrap = styled.div `
  width: calc(100vw / 2);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: ${(props) => (props.reverse ? 0 : "auto")};
  right: ${(props) => (props.reverse ? "auto" : 0)};

  img {
    // max-width: calc(100% - 96px);
    max-height: 100%;
  }

  ${MQ.maxSm} {
    position: relative;
    width: 100vw;
    text-align: center;
    img {
      width: calc(100vw - 100px);
      margin: 60px auto;
    }
  }
`;
const Card = styled.div `
  background: ${(props) => (props.bgColor ? Colors[props.bgColor] : Colors.white)};
  color: ${(props) => (props.bgColor ? Colors.white : Colors.black)};
  padding: ${(props) => (props.pOnly ? "24px 24px 24px 0" : "24px")};
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.wide ? "70%" : "412px")};
  cursor: ${(props) => (props.pointer ? "pointer" : "auto")};

  span + span {
    margin-top: 18px;
  }

  span.headline-1 + span {
    margin-top: 12px;
  }

  span.headline-1 + a {
    margin-top: 12px;
  }

  span.headline-1:last-child {
    margin-bottom: -8px;
  }

  a {
    margin-top: 18px;
  }

  ${MQ.md} {
    width: ${(props) => (props.wide ? "90%" : "354px")};
  }

  ${LANDSCAPE} {
    width: ${(props) => (props.wide ? "90%" : "354px")};
  }

  ${MQ.sm} {
    width: ${(props) => (props.wide ? "90%" : "230px")};
    padding: 16px;
  }

  ${PORTRAIT} {
    width: ${(props) => (props.wide ? "90%" : "230px")};
    padding: 16px;
  }

  ${MQ.maxSm} {
    width: ${(props) => (props.wide ? "90%" : "230px")};
    padding: 16px;
  }
`;
const ClickSpan = styled.span `
  cursor: pointer;
`;
const XProductModule = ({ bgColor, bodyText, typeText, headlineText, ctaText, colorCard, artist, isBook, cImgHeight, cImgWidth, bgImg, mBgImg, mImgHeight, mImgWidth, ctaUrl, image, changeNav, cardBgColor }) => {
    const { width } = useWindowSize();
    const imgSrc = width < 768 ? mBgImg : bgImg;
    const wrapWidth = window.innerWidth < 768 ? window.innerWidth : window.innerWidth / 2;
    let imgHeight = cImgHeight;
    if (cImgWidth && cImgHeight) {
        imgHeight = ((wrapWidth - 100) / cImgWidth) * cImgHeight;
    }
    const pOnly = !!(!typeText && !headlineText && !ctaText && bodyText);
    const history = useHistory();
    const pathname = useLocation().pathname.toLowerCase();
    // @ts-ignore
    const textColor = ["yellow", "red", "white"].includes(cardBgColor) ? "black" : "white";
    const [hovering, setHovering] = useState(false);
    const reverse = !!(ctaUrl === null || ctaUrl === void 0 ? void 0 : ctaUrl.includes("basquiat-handbook"));
    const special = !!(ctaUrl === null || ctaUrl === void 0 ? void 0 : ctaUrl.includes("kaws-handbook"));
    const pharrell = !!(ctaUrl === null || ctaUrl === void 0 ? void 0 : ctaUrl.includes("pharrell-isms"));
    return (React.createElement(ModuleWrap, { bgColor: bgColor, imgHeight: imgHeight, bgImg: bgImg, isBook: isBook },
        bgImg && React.createElement("img", { src: imgSrc, className: "bg-img" }),
        isBook &&
            artist &&
            (ctaUrl ? (React.createElement("a", { href: ctaUrl },
                React.createElement(Book3d, { artist: artist, focused: true, isAbsolute: true }))) : (React.createElement(Book3d, { artist: artist, focused: true, isAbsolute: true }))),
        React.createElement(CardWrap, { reverse: reverse },
            React.createElement(Card, { wide: reverse || special, bgColor: cardBgColor || "white", pOnly: pOnly, pointer: !!ctaUrl, onMouseLeave: () => {
                    setHovering(false);
                }, onMouseEnter: () => {
                    setHovering(true);
                }, onClick: () => {
                    if (ctaUrl) {
                        window.location.href = ctaUrl;
                    }
                } },
                typeText && (React.createElement(ClickSpan, { onClick: () => {
                        changeNav && changeNav("store");
                        if (pathname !== "/store") {
                            history.push("/store");
                        }
                        window.scrollTo(0, 0);
                    } },
                    React.createElement(Text, { size: "body", color: textColor }, typeText))),
                headlineText && (React.createElement(Headline, { size: 1, color: textColor }, headlineText)),
                bodyText && (React.createElement(Text, { size: "body", color: textColor }, reverse ? (React.createElement(React.Fragment, null,
                    "Jean-Michel Basquiat Handbook provides a concise introduction to the widely popular artist Jean-Michel Basquiat. This compact primer begins with a portrait of the young Basquiat\u2019s family life and discusses the development of his groundbreaking style through the recurrent themes of his practice: urban life, the human figure, music and sports, to name just a few. The backend of the book provides a sampling of sketches from Basquiat\u2019s notebooks, a chronology, and incisive, original essays from the late curator and historian Henry Geldzahler, and renowned scholar Henry Louis Gates Jr.",
                    React.createElement("br", null),
                    React.createElement("br", null),
                    "Edited with foreword by Larry Warsh. Text by Henry Geldzahler, Henry Louis Gates, Jr.",
                    React.createElement("br", null),
                    "Flexibound | $18.95 | 9798988928607 | 162 pages | 4.5 x 7 in. | 67 color | 11 b/w.",
                    React.createElement("br", null),
                    "Global distribution: Ingram, Baker & Taylor, Barnes & Nobles, Amazon")) : special ? (React.createElement(React.Fragment, null,
                    "KAWS Handbook is the essential guidebook to the iconic, multidisciplinary artist KAWS. Throughout his career, KAWS\u2019 work has spanned painting, sculpture and printmaking along with fashion, merchandise and toy production, blurring the lines between commercial and fine art. Insightful and engaging, KAWS Handbook connects the dots among the many facets of KAWS\u2019 creative practice and sheds new light on one of contemporary art\u2019s most impactful artists.",
                    React.createElement("br", null),
                    React.createElement("br", null),
                    "Edited with foreword by Larry Warsh. Text by Carlo McCormick",
                    React.createElement("br", null),
                    "Flexibound | $18.95 | 9798988928614 | 106 pages | 4.5 x 7 in. | 63 color",
                    React.createElement("br", null),
                    "Global distribution: Ingram, Baker & Taylor, Barnes & Nobles, Amazon")) : pharrell ? (React.createElement(React.Fragment, null, "Pharrell-isms is an essential, inspiring collection of quotations about creativity, social justice, and more from musician, producer, artist, and designer Pharrell Williams. Gathered from interviews and other sources, this compelling and provocative collection of quotations offers new insights into the personality and creative process of one of today\u2019s most exciting and intriguing artists.")) : (bodyText))),
                ctaText && ctaUrl && (React.createElement(Button, { isHeadline: true, color: textColor, href: ctaUrl, hovering: hovering }, ctaText)))),
        !isBook && image && (React.createElement(ImgWrap, { reverse: reverse },
            React.createElement("img", { src: reverse && width < 768
                    ? "https://nmr-assets.s3.amazonaws.com/images/basquiat-handbook-mobile.png"
                    : special && width < 768
                        ? "https://nmr-assets.s3.amazonaws.com/images/kaws-handbooks-mobile.png"
                        : image })))));
};
const mapStateToProps = ({ nav, cart }) => {
    return { nav, cart };
};
export const ProductModule = connect(mapStateToProps, {
    changeNav: NavActions.changeNav,
    changeArtist: NavActions.changeArtist
})(XProductModule);
