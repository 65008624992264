import React, { useState } from "react";
import { styled } from "linaria/react";
import { NewsletterForm, Text, Headline } from "@common";
import { Colors, mq, LANDSCAPE } from "@styles";
const MQ = mq;
const ModuleWrap = styled.div `
  width: 412px;
  position: fixed;
  bottom: 48px;
  right: 48px;
  min-height: 200px;
  mix-blend-mode: difference;
  display: ${(props) => (props.hidden ? "none" : "block")};
  opacity: ${(props) => (props.show ? 1 : 0)};
  z-index: ${(props) => (props.show ? 4 : 0)};
  transition: opacity 1s;

  ${MQ.md} {
    width: 354px;
  }

  ${LANDSCAPE} {
    width: 354px;
  }

  ${MQ.sm} {
    width: 354px;
  }

  ${MQ.maxSm} {
    width: 100%;
    bottom: 0;
    right: 0;
  }
`;
const BlackWrap = styled.div `
  background-color: rgba(0, 0, 0, 0.9);
  width: 412px;
  height: 201px;
  position: fixed;
  bottom: 48px;
  right: 48px;
  z-index: ${(props) => (props.hidden ? 0 : 3)};
  display: ${(props) => (props.hidden ? "none" : "block")};
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: opacity 1s;
  ${MQ.md} {
    width: 354px;
  }

  ${LANDSCAPE} {
    width: 354px;
  }

  ${MQ.sm} {
    width: 354px;
  }

  ${MQ.maxSm} {
    bottom: 33px;
    right: 0;
    width: 100%;
    height: 173px;
  }
`;
const EndBlackWrap = styled.div `
  display: none;

  ${MQ.maxSm} {
    z-index: 3;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 33px;
    display: ${(props) => (props.hidden ? "none" : "block")};
    opacity: ${(props) => (props.show ? 1 : 0)};
    transition: all 1s;
    position: fixed;
    background-color: black;
  }
`;
const Wrap = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 3;
  position: relative;
  min-height: 200px;
  background: ${Colors.blue};
  mix-blend-mode: difference;

  ${MQ.md} {
    width: 354px;
  }

  ${LANDSCAPE} {
    width: 354px;
  }

  ${MQ.sm} {
    width: 354px;
  }

  ${MQ.maxSm} {
    width: 100%;
  }
`;
const PreWrap = styled.div `
  position: relative;
  width: 100%;
  padding: 24px 24px 0;

  ${MQ.maxSm} {
    padding: 0;
  }
`;
const Close = styled.a `
  color: white;
  position: absolute;
  top: 17px;
  right: 24px;
  font-size: 28px;
  line-height: 28px;
  text-decoration: none;

  ${MQ.maxSm} {
    font-size: 21px;
    line-height: 21px;
  }
`;
const ContentWrap = styled.div `
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  span.text-body {
    margin: 16px 0 0;
  }

  .newsletter-thank-you {
    margin: 24px auto;
    text-align: left;
    span {
      color: white;
    }
  }

  form {
    margin-top: 0;
    background: transparent !important;
    width: 100%;

    input {
      background-color: transparent !important;
    }

    a {
      min-width: 100px;
      &:hover {
        background: white;
        color: ${Colors.blue};
        span {
          color: ${Colors.blue};
        }
      }
    }
  }

  ${MQ.maxSm} {
    padding: 0;
    margin: 0;

    form {
      flex-direction: column;
      padding-top: 0;

      input {
        margin: 0 24px;
        width: calc(100vw - 48px);
      }

      a {
        margin-top: 24px;
        width: 100%;
        background-color: white;

        span {
          color: ${Colors.blue} !important;
        }
      }
    }
  }
`;
const TextWrap = styled.div `
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  ${MQ.maxSm} {
    padding: 24px 24px 16px;
  }
`;
const SubscribeModal = (props) => {
    const [hidden, setHidden] = useState(true);
    const [closed, setClosed] = useState(false);
    return (React.createElement(React.Fragment, null,
        React.createElement(BlackWrap, { show: props.show, hidden: props.show && !closed ? false : hidden }),
        React.createElement(ModuleWrap, { show: props.show, hidden: props.show && !closed ? false : hidden },
            React.createElement(Wrap, null,
                React.createElement(PreWrap, null,
                    React.createElement(Close, { href: "#", onClick: (e) => {
                            e.preventDefault();
                            setHidden(true);
                            setClosed(true);
                        } }, "\u00D7"),
                    React.createElement(ContentWrap, null,
                        React.createElement(TextWrap, null,
                            React.createElement(Headline, { size: 5, color: "white" }, "Subscribe To Our Newsletter"),
                            React.createElement(Text, { size: "body", color: "white" }, "Gain access to exclusive interviews with industry creatives, think pieces, trend forecasts, guides and more")),
                        React.createElement(NewsletterForm, { inverted: true }))))),
        React.createElement(EndBlackWrap, { show: props.show, hidden: props.show && !closed ? false : hidden })));
};
export { SubscribeModal };
