import React, { useState, useEffect, useRef } from "react";
import { styled } from "linaria/react";
import OutsideClickHandler from "react-outside-click-handler";
import { CNavArtists, CNavTypes } from "@types";
import { useWindowSize, useDidMountEffect } from "@utils";
import { mq, PORTRAIT, LANDSCAPE, Colors, IS_TOUCH } from "@styles";
import { Jumbo, Headline, Text } from "../Text";
import { Button } from "@common";
import { useHistory, useLocation } from "react-router";
import { NavActions, CartActions } from "@actions";
import { connect } from "react-redux";
import Client from "shopify-buy";
const client = Client.buildClient({
    domain: "nomorerulers.myshopify.com",
    storefrontAccessToken: "79d98281b869d4d66d2f6148344847f4"
});
const MQ = mq;
const TopBar = styled.div `
  mix-blend-mode: ${(props) => (props.isMix ? "difference" : "none")};
  height: 2px;
  width: calc(100vw - 172px);
  margin: 0 86px 0 62px;
  transition: ${(props) => (props.resizing ? `width 0, margin 0` : `width 0.2s, margin 0.2s`)};
  transition-delay: 0;
  left: 24px;
  top: 24px;
  position: fixed;
  z-index: ${(props) => (props.zIndex ? 4 : 3)};
  background-color: ${(props) => (props.invert ? "#000" : "#fff")};
  &:after {
    content: "";
    height: 2px;
    background-color: ${(props) => (props.invert ? "#000" : "#fff")};
    left: 24px;
    top: 24px;
    position: fixed;
    z-index: 3;
    width: 18px;
  }
  &:before {
    content: "";
    height: 2px;
    background-color: ${(props) => (props.invert ? "#000" : "#fff")};
    right: 24px;
    top: 24px;
    position: fixed;
    z-index: 3;
    width: 18px;
  }

  @keyframes animateWidth {
    0% {
      transform: scaleX(0);
    }
    100% {
      transform: scaleX(1);
    }
  }

  ${MQ.sm} {
    &:after {
      display: none;
    }
    &:before {
      display: none;
    }
    width: ${(props) => (props.iconShowing ? "calc(100vw - 96px)" : "calc(100vw)")};
    top: 28px;
    left: 0;
    margin: ${(props) => (props.iconShowing ? "0 48px" : "0")};
    transition: width 0.5s, margin 0.5s;
  }

  ${PORTRAIT} {
    &:after {
      display: none;
    }
    &:before {
      display: none;
    }
    width: ${(props) => (props.iconShowing ? "calc(100vw - 96px)" : "calc(100vw)")};
    top: 28px;
    left: 0;
    margin: ${(props) => (props.iconShowing ? "0 48px" : "0")};
    transition: width 0.5s, margin 0.5s;
  }

  ${MQ.maxSm} {
    &:after {
      display: none;
    }
    &:before {
      display: none;
    }
    width: ${(props) => (props.iconShowing ? "calc(100vw - 96px)" : "calc(100vw)")};
    top: 28px;
    left: 0;
    margin: ${(props) => (props.iconShowing ? "0 48px" : "0")};
    transition: width 0.5s, margin 0.5s;
  }
`;
const RightBar = styled.div `
  mix-blend-mode: ${(props) => (props.isMix ? "difference" : "none")};
  width: 2px;
  height: calc(100vh - 52px);
  height: calc((var(--vh, 1vh) * 100) - 52px);
  right: 24px;
  top: 26px;
  position: fixed;
  z-index: 3;
  background-color: ${(props) => (props.invert ? "#000" : "#fff")};

  ${MQ.sm} {
    display: none;
  }

  ${PORTRAIT} {
    display: none;
  }

  ${MQ.maxSm} {
    display: none;
  }
`;
const BottomBar = styled.div `
  mix-blend-mode: ${(props) => (props.isMix ? "difference" : "none")};
  height: 2px;
  width: calc(100vw - 48px);
  left: 24px;
  bottom: 24px;
  position: fixed;
  z-index: 3;
  background-color: ${(props) => (props.invert ? "#000" : "#fff")};

  ${MQ.sm} {
    display: none;
  }

  ${PORTRAIT} {
    display: none;
  }

  ${MQ.maxSm} {
    display: none;
  }
`;
const LeftBar = styled.div `
  mix-blend-mode: ${(props) => (props.isMix ? "difference" : "none")};
  width: 2px;
  height: calc(100vh - 52px);
  height: calc((var(--vh, 1vh) * 100) - 52px);
  left: 24px;
  top: 26px;
  position: fixed;
  z-index: 3;
  background-color: ${(props) => (props.invert ? "#000" : "#fff")};

  ${MQ.sm} {
    display: none;
  }

  ${PORTRAIT} {
    display: none;
  }

  ${MQ.maxSm} {
    display: none;
  }
`;
const Logo = styled.div `
  cursor: pointer;
  filter: ${(props) => (props.invert ? "invert(1)" : "none")};
  background: ${(props) => props.isFull
    ? 'url("https://nmr-frontend.s3.amazonaws.com/logos/logo-full.svg") center center no-repeat'
    : 'url("https://nmr-frontend.s3.amazonaws.com/logos/logo-nmr.svg") center center no-repeat'};
  background-size: contain;
  width: ${(props) => (props.isFull ? "180px" : "45px")};
  height: 24px;
  position: fixed;
  left: ${(props) => (props.isFull ? "calc(50vw - 90px)" : "calc(50vw - 22.5px)")};
  mix-blend-mode: ${(props) => (props.isMix ? "difference" : "none")};
  top: 14px;
  z-index: ${(props) => (props.zIndex ? 6 : 6)};

  ${MQ.sm} {
    top: 16px;
    width: 56px;
    height: 28px;
    left: calc(50vw - 28px);
    background: url("https://nmr-frontend.s3.amazonaws.com/logos/logo-nmr.svg") center center no-repeat;
  }

  ${PORTRAIT} {
    top: 16px;
    width: 56px;
    height: 28px;
    left: calc(50vw - 28px);
    background: url("https://nmr-frontend.s3.amazonaws.com/logos/logo-nmr.svg") center center no-repeat;
  }

  ${MQ.maxSm} {
    top: 16px;
    width: 56px;
    height: 28px;
    left: calc(50vw - 28px);
    background: url("https://nmr-frontend.s3.amazonaws.com/logos/logo-nmr.svg") center center no-repeat;
  }
`;
const TopFrame = styled.div `
  height: 60px;
  width: 100vw;
  left: 0;
  top: 0;
  position: fixed;
  z-index: ${(props) => (props.zIndex ? 5 : 4)};
  mix-blend-mode: ${(props) => (props.isMix ? "difference" : "none")};
  cursor: pointer;
`;
const NavIcon = styled.div `
  display: ${(props) => (props.show ? "block" : "none")};
  left: ${(props) => (props.rightAlign ? "auto" : "44px")};
  right: ${(props) => (props.rightAlign ? "44px" : "auto")};
  z-index: 4;
  position: fixed;
  top: 18px;
  width: 42px;
  height: 14px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;

  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 30px;
    background-color: ${(props) => (props.invert ? "#000" : "#fff")};
    border-radius: 0;
    opacity: 1;
    left: 6px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }

  span:nth-child(1) {
    top: 0px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  span:nth-child(2) {
    top: 6px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  span:nth-child(3) {
    top: 12px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  &.close span:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: -5px;
    left: 8px;
  }

  &.close span:nth-child(2) {
    width: 0%;
    opacity: 0;
  }

  &.close span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 17px;
    left: 8px;
  }

  &.arrow {
    overflow: hidden;
  }

  &.arrow span:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 6px;
    left: 7px;
    width: 12px;
  }

  &.arrow span:nth-child(2) {
  }

  &.arrow span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 6px;
    left: 7px;
    width: 12px;
  }

  ${MQ.sm} {
    display: block;
    width: 24px;
    left: ${(props) => (props.rightAlign ? "auto" : props.show ? "12px" : "-80px")};
    right: ${(props) => (props.rightAlign ? (props.show ? "12px" : "-80px") : "auto")};
    top: 22px;
    opacity: ${(props) => (props.show ? 1 : 0)};
    transition: all 0.5s;

    span {
      display: block;
      position: absolute;
      height: 2px;
      width: 24px;
      background-color: ${(props) => (props.invert ? "#000" : "#fff")};
      border-radius: 0;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.25s ease-in-out;
      -moz-transition: 0.25s ease-in-out;
      -o-transition: 0.25s ease-in-out;
      transition: 0.25s ease-in-out;
    }

    span:nth-child(1) {
      top: 0px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }

    span:nth-child(2) {
      top: 6px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }

    span:nth-child(3) {
      top: 12px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }

    &.close span:nth-child(1) {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
      top: -3px;
      left: 4px;
    }

    &.close span:nth-child(2) {
      width: 0%;
      opacity: 0;
    }

    &.close span:nth-child(3) {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
      top: 14px;
      left: 4px;
    }

    &.arrow span:nth-child(1) {
      left: 0;
      top: 5.5px;
    }

    &.arrow span:nth-child(2) {
      width: 30px;
      left: 2px;
    }

    &.arrow span:nth-child(3) {
      left: 0;
    }
  }

  ${PORTRAIT} {
    display: block;
    width: 24px;
    left: ${(props) => (props.rightAlign ? "auto" : props.show ? "12px" : "-80px")};
    right: ${(props) => (props.rightAlign ? (props.show ? "12px" : "-80px") : "auto")};
    top: 22px;
    opacity: ${(props) => (props.show ? 1 : 0)};
    transition: all 0.5s;

    span {
      display: block;
      position: absolute;
      height: 2px;
      width: 24px;
      background-color: ${(props) => (props.invert ? "#000" : "#fff")};
      border-radius: 0;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.25s ease-in-out;
      -moz-transition: 0.25s ease-in-out;
      -o-transition: 0.25s ease-in-out;
      transition: 0.25s ease-in-out;
    }

    span:nth-child(1) {
      top: 0px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }

    span:nth-child(2) {
      top: 6px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }

    span:nth-child(3) {
      top: 12px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }

    &.close span:nth-child(1) {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
      top: -3px;
      left: 4px;
    }

    &.close span:nth-child(2) {
      width: 0%;
      opacity: 0;
    }

    &.close span:nth-child(3) {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
      top: 14px;
      left: 4px;
    }

    &.arrow span:nth-child(1) {
      left: 0;
      top: 5.5px;
    }

    &.arrow span:nth-child(2) {
      width: 30px;
      left: 2px;
    }

    &.arrow span:nth-child(3) {
      left: 0;
    }
  }

  ${MQ.maxSm} {
    display: block;
    width: 24px;
    left: ${(props) => (props.rightAlign ? "auto" : props.show ? "12px" : "-80px")};
    right: ${(props) => (props.rightAlign ? (props.show ? "12px" : "-80px") : "auto")};
    top: 22px;
    opacity: ${(props) => (props.show ? 1 : 0)};
    transition: all 0.5s;

    span {
      display: block;
      position: absolute;
      height: 2px;
      width: 24px;
      background-color: ${(props) => (props.invert ? "#000" : "#fff")};
      border-radius: 0;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.25s ease-in-out;
      -moz-transition: 0.25s ease-in-out;
      -o-transition: 0.25s ease-in-out;
      transition: 0.25s ease-in-out;
    }

    span:nth-child(1) {
      top: 0px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }

    span:nth-child(2) {
      top: 6px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }

    span:nth-child(3) {
      top: 12px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }

    &.close span:nth-child(1) {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
      top: -3px;
      left: 4px;
    }

    &.close span:nth-child(2) {
      width: 0%;
      opacity: 0;
    }

    &.close span:nth-child(3) {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
      top: 14px;
      left: 4px;
    }

    &.arrow span:nth-child(1) {
      left: 0;
      top: 5.5px;
    }

    &.arrow span:nth-child(2) {
      width: 30px;
      left: 2px;
    }

    &.arrow span:nth-child(3) {
      left: 0;
    }
  }
`;
const PreNav = styled.div `
  background-color: ${(props) => (props.full ? "rgba(0, 0, 0, 1)" : "rgba(0, 0, 0, 0.9)")};
  width: 100vw;
  height: 100vh;
  height: calc((var(--vh, 1vh) * 100));
  position: fixed;
  transition: opacity 1s ease, background-color 0.5s;
  z-index: ${(props) => (props.showing ? 3 : -1)};
  opacity: ${(props) => (props.showing ? 1 : 0)};
`;
const InfoWrap = styled.div `
  width: 50vw;
  height: 100vw;
  height: calc((var(--vh, 1vh) * 100));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${Colors.white};

  ${MQ.maxSm} {
    width: 85vw;
  }
`;
const Info = styled.div `
  width: 100vw;
  height: 100vh;
  height: calc((var(--vh, 1vh) * 100));
  background: ${Colors.white};
  position: fixed;
  transition: opacity 1s ease;
  z-index: ${(props) => (props.showing ? 3 : -1)};
  opacity: ${(props) => (props.showing ? 1 : 0)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 248px;

  span + span {
    margin-top: 24px;
  }

  a {
    text-decoration: none;
  }

  ${MQ.maxSm} {
    padding: 0 48px;
  }
`;
const Nav = styled.div `
  width: 100vw;
  height: 100vh;
  height: calc((var(--vh, 1vh) * 100));
  background: ${(props) => (props.altBg ? Colors.red : Colors.blue)};
  mix-blend-mode: difference;
  position: fixed;
  transition: opacity 1s ease, background-color 1s;
  z-index: ${(props) => (props.navShow ? 3 : -1)};
  opacity: ${(props) => (props.showing ? 1 : 0)};
  display: flex;
  a {
    text-decoration: none;
  }
`;
const NavMenu = styled.div `
  width: 100vw;
  height: 100vh;
  background: ${(props) => (props.altBg ? Colors.red : Colors.blue)};
  height: calc((var(--vh, 1vh) * 100));
  position: fixed;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: background-color 1s;
  padding: 0 25%;
`;
const CartWrap = styled.div `
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 12px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  border: ${(props) => (props.invert ? "2px solid #000" : "2px solid #fff")};
  color: ${(props) => (props.invert ? "#000" : "#fff")};

  ${MQ.maxSm} {
    line-height: 20px;
  }
`;
const CartIcon = styled.div `
  opacity: ${(props) => (props.show ? 1 : 0)};
  width: 42px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  position: fixed;
  right: 43px;
  top: 13px;
  cursor: pointer;

  ${MQ.sm} {
    display: block;
    line-height: 20px;
    width: 24px;
    top: 16px;
    opacity: 1;
    right: ${(props) => (props.show ? "12px" : "-80px")};
    transition: all 0.5s;
  }

  ${PORTRAIT} {
    display: block;
    line-height: 20px;
    width: 24px;
    top: 16px;
    opacity: 1;
    right: ${(props) => (props.show ? "12px" : "-80px")};
    transition: all 0.5s;
  }

  ${MQ.maxSm} {
    display: block;
    line-height: 20px;
    width: 24px;
    top: 16px;
    opacity: 1;
    right: ${(props) => (props.show ? "12px" : "-80px")};
    transition: all 0.5s;
  }
`;
const NavRow = styled.div `
  display: flex;
  width: 50vw;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;

  ${MQ.sm} {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;

    & + div {
      margin-top: 8px;
    }

    a + a {
      margin-top: 8px;
    }
  }

  ${PORTRAIT} {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;

    & + div {
      margin-top: 8px;
    }

    a + a {
      margin-top: 8px;
    }
  }

  ${MQ.maxSm} {
    width: 70vw;
    display: flex;
    flex-direction: column;
    align-items: center;

    & + div {
      margin-top: 0px;
    }

    a + a {
      margin-top: 0px;
    }
  }
`;
const StrikeThroughLink = styled.a `
  position: relative;

  & + a {
    margin-left: 12px;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 17px;
    width: 0;
    height: 8px;
    background-color: ${(props) => (props.hollow ? Colors[props.color] : Colors.white)};
    border: 1px solid #fff;
    opacity: 0;
    transition: width 0.5s;

    ${MQ.xl} {
      top: 26px;
    }
  }

  &:hover:after {
    opacity: 1;
    width: 100%;
  }

  ${IS_TOUCH} {
    &:after {
      display: none;
    }
  }

  ${MQ.sm} {
    & + a {
      margin-left: 0;
    }
  }

  ${PORTRAIT} {
    & + a {
      margin-left: 0;
    }
  }

  ${MQ.maxSm} {
    font-size: 4rem;
    line-height: 0.7em;

    & + a {
      margin-left: 0;
    }
  }
`;
const Cart = styled.div `
  width: 417px;
  height: 100vh;
  height: calc((var(--vh, 1vh) * 100));
  background: #fff;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  right: ${(props) => (props.showing ? "0px" : "-417px")};
  padding: 24px;
  z-index: ${(props) => (props.cartShow ? 4 : -1)};
  transition: right 0.5s;

  ${MQ.md} {
    width: 50%;
    right: ${(props) => (props.showing ? 0 : "-50vw")};
    z-index: ${(props) => (props.cartShow ? (props.zIndex ? 4 : 3) : -1)};
  }

  ${LANDSCAPE} {
    width: 50%;
    right: ${(props) => (props.showing ? 0 : "-50vw")};
    z-index: ${(props) => (props.cartShow ? (props.zIndex ? 4 : 3) : -1)};
  }

  ${MQ.sm} {
    width: 50%;
    right: ${(props) => (props.showing ? 0 : "-50vw")};
    z-index: ${(props) => (props.cartShow ? (props.zIndex ? 4 : 3) : -1)};
  }

  ${PORTRAIT} {
    width: 50%;
    right: ${(props) => (props.showing ? 0 : "-50vw")};
    z-index: ${(props) => (props.cartShow ? (props.zIndex ? 4 : 3) : -1)};
  }

  ${MQ.maxSm} {
    width: 100%;
    right: ${(props) => (props.showing ? 0 : "-100vw")};
    z-index: ${(props) => (props.cartShow ? (props.zIndex ? 4 : 3) : -1)};
  }
`;
const CartItemWrap = styled.div `
  border-top: 1px solid #000;
  width: 100%;

  ${MQ.md} {
    margin-top: 52px;
  }

  ${LANDSCAPE} {
    margin-top: 52px;
  }

  ${MQ.sm} {
    margin-top: 52px;
  }

  ${PORTRAIT} {
    margin-top: 52px;
  }

  ${MQ.maxSm} {
    margin-top: 52px;
  }
`;
const CartItem = styled.div `
  display: flex;
  border-bottom: 1px solid #000;
  padding: 12px 0 12px;
`;
const CartLine = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;
const CartImg = styled.img `
  width: 48px;
  margin-right: 12px;
`;
const CartLineDetails = styled.div `
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;
const CartTitle = styled.div `
  display: flex;
  flex-direction: column;

  span + span {
    margin-top: 12px;
  }
`;
const CartSummaryDetail = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;
const CartSummary = styled.div `
  border-bottom: 1px solid #000;
  padding: 0;
  width: 100%;
  a:last-child {
    margin: 12px 0;
    width: 100%;
    display: block;
    text-align: center;
  }
`;
const QRCode = styled.img `
  position: absolute;
  bottom: 80px;
  width: 100px;

  ${MQ.md} {
    display: none;
  }

  ${LANDSCAPE} {
    display: none;
  }

  ${MQ.sm} {
    display: none;
  }

  ${PORTRAIT} {
    display: none;
  }

  ${MQ.maxSm} {
    display: none;
  }
`;
const CartPrice = styled.div ``;
const MenuItems = {
    "virgil-abloh": "Virgil Abloh",
    "ai-wei-wei": "Ai Wei-Wei",
    "daniel-arsham": "Daniel Arsham",
    "jean-michel-basquiat": "Jean-Michel Basquiat",
    // "niki-de-saint-phalle": "Niki de Saint Phalle",
    futura: "Futura",
    "keith-haring": "Keith Haring",
    "damien-hirst": "Damien Hirst",
    // "jenny-holzer": "Jenny Holzer",
    "marilyn-minter": "Marilyn Minter",
    warhol: "Andy Warhol",
    pharrell: "Pharrell Williams"
};
const XFrame = (props) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const history = useHistory();
    const pathname = useLocation().pathname.toLowerCase();
    const { width, isPortrait } = useWindowSize();
    const isTouch = "ontouchstart" in document.documentElement;
    const [navShowing, setNavShowing] = useState(false);
    const [iconShowing, setIconShowing] = useState(true);
    const [show2ndMenu, setShow2ndMenu] = useState(false);
    const [infoShowing, setInfoShowing] = useState(false);
    const [navShow, setNavShow] = useState(false);
    const [cartShow, setCartShow] = useState(false);
    const [cartOpen, setCartOpen] = useState(false);
    const navShowingRef = useRef(false);
    const cartShowingRef = useRef(false);
    const autoCartTimerRef = useRef(null);
    const cartTimerRef = useRef(null);
    const navTimerRef = useRef(null);
    const [navState, setNavState] = useState(isTouch ? "menu" : "none");
    const [navShowState, setNavShowState] = useState(pathname === "/about" ? "full" : "partial");
    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD"
    });
    const subtotal = ((_a = props === null || props === void 0 ? void 0 : props.cart) === null || _a === void 0 ? void 0 : _a.subtotal) || 0;
    const subtotalDisplay = formatter.format(subtotal);
    const cartCount = ((_b = props === null || props === void 0 ? void 0 : props.cart) === null || _b === void 0 ? void 0 : _b.totalQty) || 0;
    useEffect(() => {
        if (pathname === "/") {
            props.changeNav && props.changeNav("home");
        }
        else {
            CNavTypes.forEach((typeNav) => {
                if (pathname === `/${typeNav}`) {
                    if (typeNav === "about") {
                        setIconShowing(true);
                        setNavShowing(true);
                        navShowingRef.current = true;
                        setNavShow(true);
                        setNavShowState("full");
                        setNavState("arrow");
                        setInfoShowing(true);
                    }
                    props.changeNav && props.changeNav(typeNav);
                }
            });
            CNavArtists.forEach((artistNav) => {
                if (pathname === `/${artistNav}`) {
                    props.changeArtist && props.changeArtist(artistNav);
                }
            });
        }
    }, [pathname]);
    const closeNav = () => {
        setNavShowing(false);
        navShowingRef.current = false;
        setNavShowState("partial");
        setNavState("none");
        setShow2ndMenu(false);
        if (navTimerRef.current) {
            clearTimeout(navTimerRef.current);
        }
        navTimerRef.current = setTimeout(() => {
            if (navShowingRef.current === false) {
                setNavShow(false);
            }
        }, 1000);
    };
    const openCart = () => {
        setCartOpen(true);
        setCartShow(true);
        cartShowingRef.current = true;
    };
    const closeCart = () => {
        setCartOpen(false);
        if (cartTimerRef.current) {
            clearTimeout(cartTimerRef.current);
        }
        cartTimerRef.current = setTimeout(() => {
            if (cartShowingRef.current === true) {
                setCartShow(false);
                cartShowingRef.current = false;
            }
        }, 5000);
    };
    const navHome = () => {
        setInfoShowing(false);
        props.changeNav && props.changeNav("home");
        closeNav();
        if (pathname !== "/") {
            history.push("/");
        }
    };
    const navIconClick = () => {
        closeCart();
        if (width < 768 || isTouch) {
            if (navState === "menu") {
                setNavShow(true);
                setNavShowing(!navShowing);
                navShowingRef.current = !navShowing;
                setNavShowState("full");
                setNavState("arrow");
            }
            else {
                if (infoShowing) {
                    setInfoShowing(false);
                    if (pathname !== "/") {
                        history.push("/");
                    }
                }
                else if (show2ndMenu) {
                    setShow2ndMenu(false);
                }
                else {
                    setIconShowing(true);
                    setNavState("menu");
                    setNavShowState("partial");
                    setNavShowing(false);
                }
            }
        }
        else {
            if (navState === "close") {
                setNavState("menu");
                setNavShowState("partial");
            }
            else if (navState === "menu") {
                setNavShowState(navShowState === "partial" ? "full" : "partial");
                setNavState("close");
            }
            else if (navState === "arrow") {
                if (width > 768) {
                    setShow2ndMenu(false);
                    setInfoShowing(false);
                    if (pathname !== "/") {
                        history.push("/");
                    }
                    setNavState("close");
                    setNavShowState("full");
                }
            }
        }
    };
    const openNav = () => {
        setNavShowing(true);
        navShowingRef.current = true;
        setNavShow(true);
        setNavState("menu");
    };
    const isMixTopFrame = ((width > 768 || !isTouch) && !navShowing) || (navShowState !== "full" && (width < 768 || isTouch));
    useDidMountEffect(() => {
        var _a, _b;
        if (((_a = props.cart) === null || _a === void 0 ? void 0 : _a.totalQty) && ((_b = props.cart) === null || _b === void 0 ? void 0 : _b.totalQty) > 0) {
            openNav();
            openCart();
            if (autoCartTimerRef.current) {
                clearTimeout(autoCartTimerRef.current);
            }
            autoCartTimerRef.current = setTimeout(() => {
                if (cartShowingRef.current === true) {
                    closeCart();
                    closeNav();
                }
            }, 3000);
        }
    }, [((_c = props.cart) === null || _c === void 0 ? void 0 : _c.totalQty) || 0]);
    useEffect(() => {
        var _a, _b;
        if ((_a = props.cart) === null || _a === void 0 ? void 0 : _a.checkoutId) {
            client.checkout
                .fetch((_b = props.cart) === null || _b === void 0 ? void 0 : _b.checkoutId)
                .then((checkout) => {
                if (checkout === null) {
                    props.clearCheckout && props.clearCheckout();
                }
            })
                .catch(() => {
                props.clearCheckout && props.clearCheckout();
            });
        }
    }, [(_d = props.cart) === null || _d === void 0 ? void 0 : _d.checkoutId]);
    useEffect(() => {
        props.toggleMenu && props.toggleMenu(navShowState === "full");
    }, [navShowState]);
    let n = 0;
    return (React.createElement(React.Fragment, null,
        React.createElement(TopFrame, { zIndex: isPortrait && infoShowing && cartOpen, isMix: isMixTopFrame && !cartOpen, onClick: (e) => {
                var _a;
                const htmlTarget = e.target;
                const isTopFrame = (_a = htmlTarget.classList.item(0)) === null || _a === void 0 ? void 0 : _a.includes("TopFrame_");
                if (isTopFrame && !isTouch && width > 768) {
                    navIconClick();
                }
            }, onMouseEnter: () => {
                if (navState === "none" && !isTouch && width > 768) {
                    openNav();
                }
            }, onMouseLeave: () => {
                if (navShowState === "partial" && !cartOpen && !isTouch && width > 768) {
                    closeNav();
                }
            } },
            React.createElement(Logo, { className: !!props.loading ? "pulsate" : "", zIndex: isPortrait && infoShowing && cartOpen, isMix: !navShowing && !(isPortrait && cartOpen), isFull: navState !== "none", invert: infoShowing || (isPortrait && cartOpen), onClick: () => {
                    navHome();
                } }),
            React.createElement(NavIcon, { zIndex: isPortrait && infoShowing && cartOpen, invert: infoShowing || (width < 768 && cartOpen), show: iconShowing, className: navState, onClick: () => {
                    navIconClick();
                } },
                React.createElement("span", null),
                React.createElement("span", null),
                React.createElement("span", null)),
            cartOpen ? (React.createElement(NavIcon, { zIndex: isPortrait && infoShowing && cartOpen, invert: true, show: true, rightAlign: true, className: "close", onClick: () => {
                    closeCart();
                    closeNav();
                } },
                React.createElement("span", null),
                React.createElement("span", null),
                React.createElement("span", null))) : (React.createElement(CartIcon, { zIndex: isPortrait && infoShowing && cartOpen, invert: infoShowing, show: ((isPortrait || navShowState === "partial") && (!infoShowing || isPortrait)) || iconShowing, onClick: () => {
                    openCart();
                } },
                React.createElement(CartWrap, { invert: infoShowing },
                    React.createElement(Headline, { size: 6, color: infoShowing ? "black" : "white" }, ((_e = props.cart) === null || _e === void 0 ? void 0 : _e.totalQty) || 0))))),
        React.createElement(PreNav, { showing: navShowState === "full", full: infoShowing }),
        React.createElement(Nav, { showing: !infoShowing && navShowing, navShow: navShow, showState: navShowState, onClick: () => { }, altBg: show2ndMenu }, navShowState === "full" &&
            (show2ndMenu ? (React.createElement(NavMenu, { altBg: show2ndMenu },
                React.createElement(NavRow, null, Object.entries(MenuItems).map(([hash, name]) => {
                    n += 1;
                    return n % 2 == 0 ? (React.createElement(StrikeThroughLink, { href: "#", hollow: false, color: "blue", onClick: (e) => {
                            e.preventDefault();
                            props.changeArtist && props.changeArtist(`${hash}`);
                            closeNav();
                            if (pathname !== `/${hash}`) {
                                history.push(`/${hash}`);
                            }
                            window.scrollTo(0, 0);
                        } },
                        React.createElement(Jumbo, { size: "max", stroke: true, color: "transparent", invert: true }, name))) : (React.createElement(StrikeThroughLink, { hollow: true, color: show2ndMenu ? "red" : "blue", href: "#", onClick: (e) => {
                            e.preventDefault();
                            props.changeArtist && props.changeArtist(`${hash}`);
                            closeNav();
                            if (pathname !== `/${hash}`) {
                                history.push(`/${hash}`);
                            }
                            window.scrollTo(0, 0);
                        } },
                        React.createElement(Jumbo, { size: "max", stroke: false, color: "white" }, name)));
                })))) : (React.createElement(NavMenu, { altBg: show2ndMenu },
                React.createElement(NavRow, null,
                    React.createElement(StrikeThroughLink, { href: "#", hollow: false, color: "blue", onClick: (e) => {
                            e.preventDefault();
                            navHome();
                        } },
                        React.createElement(Jumbo, { size: "max", stroke: true, color: "transparent", invert: true }, "Home")),
                    React.createElement(StrikeThroughLink, { hollow: true, color: "blue", href: "#", onClick: (e) => {
                            e.preventDefault();
                            setNavState("arrow");
                            setInfoShowing(true);
                            props.changeNav && props.changeNav("about");
                            if (pathname !== "/about") {
                                history.push("/about");
                            }
                        } },
                        React.createElement(Jumbo, { size: "max", stroke: false, color: "white" }, "Info")),
                    React.createElement(StrikeThroughLink, { href: "#", hollow: false, color: "blue", onClick: (e) => {
                            e.preventDefault();
                            setShow2ndMenu(true);
                            setNavState("arrow");
                        } },
                        React.createElement(Jumbo, { size: "max", stroke: true, color: "transparent", invert: true }, "Artists")),
                    React.createElement(StrikeThroughLink, { href: "/store", hollow: true, color: "blue", onClick: (e) => {
                            e.preventDefault();
                            props.changeNav && props.changeNav("store");
                            closeNav();
                            if (pathname !== "/store") {
                                history.push("/store");
                            }
                        } },
                        React.createElement(Jumbo, { size: "max", stroke: false, color: "white" }, "Store")),
                    React.createElement(StrikeThroughLink, { href: "#", hollow: false, color: "blue", onClick: (e) => {
                            e.preventDefault();
                            props.changeNav && props.changeNav("voices");
                            closeNav();
                            if (pathname !== "/voices") {
                                history.push("/voices");
                            }
                            window.scrollTo(0, 0);
                        } },
                        React.createElement(Jumbo, { size: "max", stroke: true, color: "transparent", invert: true }, "Voices")),
                    React.createElement(StrikeThroughLink, { href: "#", hollow: true, color: "blue", onClick: (e) => {
                            e.preventDefault();
                            props.changeNav && props.changeNav("events");
                            closeNav();
                            if (pathname !== "/events") {
                                history.push("/events");
                            }
                            window.scrollTo(0, 0);
                        } },
                        React.createElement(Jumbo, { size: "max", stroke: false, color: "white" }, "Events")),
                    React.createElement(StrikeThroughLink, { href: "#", hollow: false, color: "blue", onClick: (e) => {
                            e.preventDefault();
                            props.changeNav && props.changeNav("newsletter");
                            closeNav();
                            if (pathname !== "/newsletter") {
                                history.push("/newsletter");
                            }
                            window.scrollTo(0, 0);
                        } },
                        React.createElement(Jumbo, { size: "max", stroke: true, color: "transparent", invert: true }, "Newsletter")),
                    React.createElement(StrikeThroughLink, { href: "https://www.instagram.com/nomorerulers/", target: "_blank", hollow: true, color: "blue" },
                        React.createElement(Jumbo, { size: "max", stroke: false, color: "white" }, "Instagram"))))))),
        React.createElement(OutsideClickHandler, { onOutsideClick: () => {
                if (cartOpen) {
                    if (width > 768 || !isTouch) {
                        closeCart();
                        closeNav();
                    }
                }
            } },
            React.createElement(Cart, { showing: cartOpen, zIndex: isPortrait && infoShowing && cartOpen, cartShow: cartShow },
                React.createElement(CartItemWrap, null, ((_f = props.cart) === null || _f === void 0 ? void 0 : _f.items) &&
                    Object.values(props.cart.items).map((item, n) => {
                        const formatter = new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD"
                        });
                        const subtotalDisplay = formatter.format(item.price);
                        return (React.createElement(CartItem, { key: `item-${item.id}` },
                            React.createElement(CartLine, null,
                                React.createElement(CartImg, { src: item.photo }),
                                React.createElement(CartLineDetails, null,
                                    React.createElement(CartTitle, null,
                                        React.createElement(Text, { color: "black", normalCase: true, size: "large" }, item.title),
                                        item.variantTitle != "Default Title" && (React.createElement(Text, { color: "charcoal", size: "body" }, item.variantTitle)),
                                        React.createElement(Text, { color: "black", normalCase: true, size: "large" }, `Quantity: ${item.qty}`),
                                        React.createElement(Text, { color: "textGrey", normalCase: true, size: "large" },
                                            React.createElement("span", { style: { cursor: "pointer" }, onClick: (e) => {
                                                    e.preventDefault();
                                                    props.removeCartItem && props.removeCartItem(item);
                                                } }, "Remove"))),
                                    React.createElement(CartPrice, null,
                                        React.createElement(Text, { size: "large" }, subtotalDisplay))))));
                    })),
                React.createElement(CartSummary, null,
                    React.createElement(CartSummaryDetail, null,
                        React.createElement(Text, { color: "black", size: "large", normalCase: true }, "Subtotal"),
                        React.createElement(Text, { color: "black", size: "large", normalCase: true }, subtotalDisplay)),
                    React.createElement(CartSummaryDetail, null,
                        React.createElement(Text, { color: "black", size: "large", normalCase: true }, "Shipping"),
                        React.createElement(Text, { color: "black", size: "large", normalCase: true }, "TBD")),
                    React.createElement(CartSummaryDetail, null,
                        React.createElement(Text, { color: "black", size: "large", normalCase: true }, "Taxes"),
                        React.createElement(Text, { color: "black", size: "large", normalCase: true }, "TBD")),
                    subtotal > 0 && ((_g = props.cart) === null || _g === void 0 ? void 0 : _g.url) && (React.createElement(Button, { isHeadline: true, color: "black", openNewTab: true, href: props.cart.url }, "Checkout"))))),
        React.createElement(Info, { showing: infoShowing },
            React.createElement(InfoWrap, null,
                React.createElement(Headline, { size: 3 }, "NO MORE RULERS (NMR) is re-defining the conversation surrounding art and creative expression today. Based in the heart of New York City, NMR is a publishing company dedicated to empowering the creative community and questioning the status quo. Our artist publications erase the boundaries between high and low with the goal of blending popular culture with realms of fine art, design, music, fashion, and technology. By partnering with global institutions and museums and focusing on topics ranging from contemporary culture to artistic process and creativity, we are re-imagining a world where art truly exists for everyone."),
                React.createElement(Text, { size: "body" }, "Partners: Longhouse Reserve, MFA Boston, Mori Arts Centre, P\u00E9rez Art Museum Miami, Princeton University Press, UCCA, Warhol Museum"),
                React.createElement(QRCode, { src: "https://nmr-frontend.s3.amazonaws.com/icons/qr.jpg" }))),
        React.createElement(TopBar, { resizing: window.resizing, zIndex: isPortrait && infoShowing && cartOpen, halfPartial: navShowState == "full", partial: navState !== "none", iconShowing: isPortrait || isTouch, isMix: isMixTopFrame && !(isPortrait && cartOpen), invert: infoShowing || (isPortrait && cartOpen) }),
        React.createElement(RightBar, { isMix: !navShowing, invert: infoShowing }),
        React.createElement(BottomBar, { isMix: !navShowing, invert: infoShowing }),
        React.createElement(LeftBar, { isMix: !navShowing, invert: infoShowing })));
};
const mapStateToProps = ({ nav, cart }) => {
    return { nav, cart };
};
export const Frame = connect(mapStateToProps, {
    changeNav: NavActions.changeNav,
    toggleMenu: NavActions.toggleMenu,
    changeArtist: NavActions.changeArtist,
    clearCheckout: CartActions.clearCheckout,
    removeCartItem: CartActions.removeCartItem
})(XFrame);
