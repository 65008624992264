import React, { useRef, useState } from "react";
import { Colors } from "@styles";
import { styled } from "linaria/react";
import { Headline, Text, Button, Marquee } from "@common";
import { mq, PORTRAIT, LANDSCAPE } from "@styles";
import { useWindowSize } from "@utils";
import { Stream } from "@cloudflare/stream-react";
import { NavActions } from "@actions";
import { connect } from "react-redux";
import { formatSlug } from "@utils";
import { useHistory, useLocation } from "react-router";
import { useInViewport } from "react-in-viewport";
const MQ = mq;
const ModuleWrap = styled.div `
  width: ${(props) => (props.half ? "50vw" : "100vw")};
  background: ${(props) => props.bgColor ? (props.bgColor === "charcoal" ? Colors.charcoalBg : Colors[props.bgColor]) : ``};
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: ${(props) => !props.isWider && !props.bgImg && !!props.cImgHeight ? `${props.cImgHeight + props.cImgPad}px` : "auto"};
  overflow-y: hidden;
  max-height: ${(props) => (props.vhHeight ? "80vh" : "auto")};

  img.bg-img {
    width: 100%;
    opacity: 0;
    transition: opacity 1s ease-in-out;
    &.in-view {
      opacity: 1;
    }
  }

  ${MQ.md} {
    max-height: ${(props) => (props.vhHeight ? "50vh" : "auto")};
  }

  ${LANDSCAPE} {
    max-height: ${(props) => (props.vhHeight ? "50vh" : "auto")};
  }

  ${MQ.sm} {
    max-height: ${(props) => (props.vhHeight ? "50vh" : "auto")};
    width: 100vw;
  }

  ${PORTRAIT} {
    max-height: ${(props) => (props.vhHeight ? "50vh" : "auto")};
    width: 100vw;
  }

  ${MQ.maxSm} {
    height: ${(props) => !!props.cImgHeight && !props.bgColor && (props.ticker || !props.bgImg) ? `${props.cImgHeight + 160}px` : "auto"};
    flex-direction: column-reverse;
    max-height: ${(props) => (props.vhHeight ? "fit-content" : "auto")};
    width: 100vw;

    img.bg-img {
      width: 100%;
      height: 100%;
      position: ${(props) => (!!props.cImgHeight && props.bgImg ? `absolute` : "relative")};
    }
  }
`;
const Card = styled.div `
  background: ${(props) => (props.bgColor ? Colors[props.bgColor] : Colors.white)};
  padding: ${(props) => (props.pOnly && props.position.includes("left") ? "24px 24px 24px 0" : "24px")};
  top: ${(props) => ["left_top", "right_top"].includes(props.position) ? "48px" : props.position.includes("middle") ? "50%" : "auto"};
  bottom: ${(props) => (["left_bottom", "right_bottom"].includes(props.position) ? "48px" : "auto")};
  left: ${(props) => (props.position.includes("left") ? "48px" : "auto")};
  right: ${(props) => (props.position.includes("right") ? "48px" : "auto")};
  position: absolute;
  display: ${(props) => (props.hidden ? "none" : "flex")};
  flex-direction: column;
  width: 412px;
  z-index: 2;
  transform: ${(props) => (props.position.includes("middle") ? "translateY(-50%)" : "none")};
  cursor: ${(props) => (props.pointer ? "pointer" : "auto")};

  span,
  a {
    color: ${(props) => props.bgColor
    ? ["white", "haring", "yellow"].includes(props.bgColor)
        ? Colors.black
        : Colors.white
    : Colors.black};
    border-color: ${(props) => props.bgColor
    ? ["white", "haring", "yellow"].includes(props.bgColor)
        ? Colors.black
        : Colors.white
    : Colors.black};
  }

  &:hover a {
    background-color: ${(props) => props.bgColor
    ? ["white", "haring", "yellow"].includes(props.bgColor)
        ? Colors.black
        : Colors.white
    : Colors.black};
  }

  a:hover {
    background-color: ${(props) => props.bgColor
    ? ["white", "haring", "yellow"].includes(props.bgColor)
        ? Colors.black
        : Colors.white
    : Colors.black};
  }

  span + a {
    margin-top: 18px;
  }

  span + span {
    margin-top: 18px;
  }

  span.headline-1 + span {
    margin-top: 12px;
  }

  span.headline-1 + a {
    margin-top: 12px;
  }

  span.headline-1:last-child {
    margin-bottom: -8px;
  }

  ${MQ.md} {
    width: 354px;
  }

  ${LANDSCAPE} {
    width: 354px;
  }

  ${MQ.sm} {
    width: 354px;
  }

  ${PORTRAIT} {
    width: 230px;
    padding: 16px;
  }

  ${MQ.maxSm} {
    width: calc(100vw - 42px);
    bottom: 21px;
    left: ${(props) => (props.cImg ? 0 : "21px")};
    top: auto;
    right: auto;
    padding: 16px;
    transform: none;
    position: ${(props) => (props.cImg ? "relative" : "absolute")};
  }
`;
const CenterTopImg = styled.img `
  height: ${(props) => (props.vhHeight ? `calc(80vh - 192px)` : `${props.imgHeight - 192}px`)};
  position: absolute;
  top: 96px;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  &.in-view {
    opacity: 1;
  }

  ${MQ.md} {
    top: auto;
  }

  ${LANDSCAPE} {
    top: auto;
  }

  ${MQ.maxSm} {
    top: 0;
    margin: 80px 0;
    height: ${(props) => props.imgHeight - 160}px;
    position: relative;
  }
`;
const CenterImg = styled.img `
  height: ${(props) => (props.vhHeight ? `calc(80vh - 192px)` : `${props.imgHeight - 192}px`)};
  position: ${(props) => (props.isAutoHeight ? "relative" : "absolute")};
  top: ${(props) => (props.isAutoHeight ? 0 : 96)};
  opacity: 0;
  transition: opacity 1s ease-in-out;
  &.in-view {
    opacity: 1;
  }

  ${MQ.md} {
    height: auto;
    position: relative;
    margin: 96px auto;
    max-width: calc(100vw - 256px);
    top: 0;
  }

  ${LANDSCAPE} {
    height: auto;
    position: relative;
    margin: 96px auto;
    max-width: calc(100vw - 256px);
    top: 0;
  }

  ${MQ.sm} {
    height: auto;
    position: relative;
    margin: 96px auto;
    max-width: calc(100vw - 208px);
    top: 0;
  }

  ${PORTRAIT} {
    height: auto;
    position: relative;
    margin: 96px auto;
    max-width: calc(100vw - 208px);
    top: 0;
  }

  ${MQ.maxSm} {
    top: 0;
    margin: 80px 0 30px;
    height: ${(props) => props.imgHeight - 160}px;
    position: relative;
  }
`;
const TopVideoWrap = styled.div `
  height: ${(props) => props.imgHeight - 192}px;
  position: absolute;
  top: 96px;

  > div {
    top: 50%;
    transform: translateY(-50%);
  }

  ${MQ.md} {
    top: auto;
  }

  ${LANDSCAPE} {
    top: auto;
  }

  ${MQ.maxSm} {
    top: 0;
    margin: 80px 0;
    height: ${(props) => props.imgHeight - 160}px;
    position: absolute;
  }
`;
const VideoWrap = styled.div `
  height: ${(props) => props.imgHeight - 192}px;
  position: ${(props) => (props.isAutoHeight ? "relative" : "absolute")};
  top: ${(props) => (props.isAutoHeight ? 0 : 96)};

  > div {
    top: 50%;
    transform: translateY(-50%);
  }

  ${MQ.md} {
    height: auto;
    position: relative;
    margin: 96px auto;
    max-width: calc(100vw - 256px);
    top: 0;
  }

  ${LANDSCAPE} {
    height: auto;
    position: relative;
    margin: 96px auto;
    max-width: calc(100vw - 256px);
    top: 0;
  }

  ${MQ.sm} {
    height: auto;
    position: relative;
    margin: 96px auto;
    max-width: calc(100vw - 208px);
    top: 0;
  }

  ${PORTRAIT} {
    height: auto;
    position: relative;
    margin: 96px auto;
    max-width: calc(100vw - 208px);
    top: 0;
  }

  ${MQ.maxSm} {
    top: 0;
    margin: 80px 0 30px;
    height: ${(props) => props.imgHeight - 160}px;
    position: relative;
  }
`;
const Caption = styled.div `
  z-index: 1;
  position: absolute;
  width: 100%;
  bottom: 64px;
  text-align: center;
  span {
    color: ${Colors.white};
  }

  &:hover {
    span {
      background-color: ${Colors.white};
      color: ${Colors.black};
    }
  }

  ${MQ.maxSm} {
    display: none;
  }
`;
const ClickSpan = styled.span `
  cursor: pointer;
`;
const StreamPlay = styled.span `
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 300px;
  height: 300px;
  background-image: url("https://nmr-assets.s3.amazonaws.com/images/play-video.png");
  background-size: contain;
  left: 50%;
  margin-left: -150px;
  z-index: ${(props) => (props.hidden ? 0 : 1)};
  cursor: pointer;

  ${PORTRAIT} {
    width: 200px;
    height: 200px;
    margin-left: -75px;
  }

  ${MQ.maxSm} {
    width: 70px;
    height: 70px;
    margin-left: -35px;
  }
`;
const XContentModule = ({ bgImg, bgColor, bodyText, typeText, headlineText, imgHeight, imgWidth, cImgWidth, cImgHeight, ctaText, ctaUrl, colorCard, centerImg, cardPosition, caption, ticker, tickerInverted, half, mBgImg, mImgHeight, mImgWidth, vhHeight, cloudflareVideoId, mobileCloudflareVideoId, changeNav, videoPlayable }) => {
    const history = useHistory();
    const pathname = useLocation().pathname.toLowerCase();
    const { width } = useWindowSize();
    const cImgPad = width < 768 ? 160 : 192;
    const imgSrc = width < 768 ? mBgImg : bgImg;
    const iHeight = width < 768 && mImgHeight ? mImgHeight : imgHeight ? imgHeight : 0;
    const iWidth = width < 768 && mImgWidth ? mImgWidth : imgWidth ? imgWidth : 0;
    const newImageWidthNumerator = width > 768 && half ? window.innerWidth / 2 : window.innerWidth;
    const isWider = !!(cImgWidth && cImgWidth > newImageWidthNumerator);
    const cIHeight = width < 768 && cImgWidth && cImgHeight
        ? ((newImageWidthNumerator - 96) / cImgWidth) * cImgHeight
        : isWider && cImgWidth && cImgHeight
            ? ((newImageWidthNumerator - 96) / cImgWidth) * cImgHeight
            : cImgHeight;
    const cIWidth = width < 768 && cImgWidth && cImgHeight
        ? ((newImageWidthNumerator - 96) / cImgWidth) * cImgWidth
        : isWider && cImgWidth && cImgHeight
            ? ((newImageWidthNumerator - 96) / cImgWidth) * cImgWidth
            : cImgWidth;
    const newImgHeight = (((!bgImg || !ticker) && width < 768) || (width > 768 && !bgImg && !ticker)) && cIHeight
        ? cIHeight + cImgPad
        : imgWidth && imgHeight
            ? (newImageWidthNumerator / iWidth) * iHeight
            : 0;
    const newImgWidth = (((!bgImg || !ticker) && width < 768) || (width > 768 && !bgImg && !ticker)) && cIWidth
        ? cIWidth
        : imgWidth && imgHeight
            ? (newImageWidthNumerator / iWidth) * iHeight
            : 0;
    const wrapHeight = !bgImg && cIHeight ? cIHeight + cImgPad : newImgHeight ? newImgHeight : 0;
    const ImgElement = ticker || bgImg ? CenterTopImg : CenterImg;
    const VideoElement = ticker || bgImg ? TopVideoWrap : VideoWrap;
    const wrapIHeight = width < 768 && ticker ? newImgHeight - 160 : cIHeight;
    const isAutoHeight = !isWider && !bgImg && !!wrapIHeight;
    const pOnly = !!(!typeText && !headlineText && !ctaText && bodyText);
    const moduleRef = useRef(null);
    const { inViewport, enterCount, leaveCount } = useInViewport(moduleRef, { threshold: 0.5 }, { disconnectOnLeave: false }, {});
    const [hovering, setHovering] = useState(false);
    const streamRef = useRef(null);
    const [cardHidden, setCartHidden] = useState(false);
    return (React.createElement(ModuleWrap, { bgColor: bgColor, cImgHeight: wrapIHeight, half: half, bgImg: bgImg, ticker: ticker, vhHeight: vhHeight, isWider: isWider, cImgPad: cImgPad, ref: moduleRef },
        !cloudflareVideoId && bgImg && React.createElement("img", { src: imgSrc, className: `${inViewport ? "in-view" : ""} bg-img` }),
        cloudflareVideoId && cImgWidth && cImgHeight && (React.createElement("div", { style: {
                width: window.innerWidth,
                height: videoPlayable && width < 768
                    ? (window.innerWidth / cImgHeight) * cImgWidth
                    : (window.innerWidth / cImgWidth) * cImgHeight
            } },
            videoPlayable && (React.createElement(StreamPlay, { hidden: cardHidden, onClick: () => {
                    if (streamRef.current) {
                        setCartHidden(true);
                        streamRef.current.muted = false;
                    }
                } })),
            React.createElement(Stream, { src: width > 768 ? cloudflareVideoId : mobileCloudflareVideoId || cloudflareVideoId, controls: !!videoPlayable, muted: true, autoplay: true, startTime: videoPlayable ? 20 : 0, width: window.innerWidth, height: (window.innerWidth / cImgWidth) * cImgHeight, loop: true, streamRef: streamRef }))),
        cardPosition && (React.createElement(Card, { bgColor: colorCard ? bgColor : undefined, position: cardPosition, cImg: centerImg, pOnly: pOnly, pointer: !!ctaUrl, hidden: !!(videoPlayable && width < 768) || cardHidden, onClick: () => {
                if (ctaUrl) {
                    window.location.href = ctaUrl;
                }
            }, onMouseLeave: () => {
                setHovering(false);
            }, onMouseEnter: () => {
                setHovering(true);
            } },
            typeText && (React.createElement(ClickSpan, { onClick: () => {
                    const textSlug = formatSlug(typeText);
                    if (textSlug && ["events", "store", "event"].includes(textSlug)) {
                        if (["events", "event"].includes(textSlug)) {
                            changeNav && changeNav("events");
                            if (pathname !== "/events") {
                                history.push("/events");
                            }
                        }
                        else {
                            changeNav && changeNav("store");
                            if (pathname !== "/store") {
                                history.push("/store");
                            }
                        }
                        window.scrollTo(0, 0);
                    }
                } },
                React.createElement(Text, { size: "body" }, typeText))),
            headlineText && React.createElement(Headline, { size: 1 }, headlineText),
            bodyText && React.createElement(Text, { size: "body" }, bodyText),
            ctaText && ctaUrl && (React.createElement(Button, { hoverBg: colorCard && bgColor ? bgColor : undefined, isHeadline: true, color: colorCard ? "white" : "black", href: ctaUrl, hovering: hovering }, ctaText)))),
        ticker && wrapHeight && (React.createElement(Marquee, { wrapHeight: wrapHeight, headlineText: headlineText, ctaText: ctaText, ctaUrl: ctaUrl, typeText: typeText, inverted: tickerInverted })),
        centerImg && cImgHeight && (React.createElement(ImgElement, { ticker: ticker, src: centerImg, imgHeight: newImgHeight, bgImg: bgImg, isAutoHeight: isAutoHeight, vhHeight: vhHeight, className: inViewport ? "in-view" : "" })),
        caption && (React.createElement(Caption, null,
            React.createElement(Text, { size: "detail" }, caption)))));
};
const mapStateToProps = ({ nav, cart }) => {
    return { nav, cart };
};
export const ContentModule = connect(mapStateToProps, {
    changeNav: NavActions.changeNav,
    changeArtist: NavActions.changeArtist
})(XContentModule);
