import React, { useRef, useState } from "react";
import { styled } from "linaria/react";
import { mq, PORTRAIT } from "@styles";
import { Text, Book3d } from "@common";
import { useInViewport } from "react-in-viewport";
const MQ = mq;
const ModuleWrap = styled.div `
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
  justify-content: center;
  align-items: center;
  max-width: 320px;
  text-align: center;

  a {
    text-decoration: none;
    margin: 24px 0 8px;
  }

  img {
    max-width: 100%;
  }

  img + span {
    margin-top: 16px;
  }

  a span {
    text-align: center;
  }

  span + span {
    margin-top: 16px;
  }

  ${MQ.sm} {
    width: 50%;
  }

  ${PORTRAIT} {
    width: 50%;
  }

  ${MQ.maxSm} {
    width: 100%;
    padding-bottom: 40px;
    max-width: 100%;
  }
`;
const ImgWrap = styled.div `
  height: 425px;
  max-height: 425px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  &.in-view {
    opacity: 1;
  }
`;
const ProductCard = ({ product, hImg, img }) => {
    var _a, _b, _c, _d;
    const [imgSrc, setImgSrc] = useState(img);
    const moduleRef = useRef(null);
    const { inViewport, enterCount, leaveCount } = useInViewport(moduleRef, { threshold: 0.25 }, { disconnectOnLeave: false }, {});
    const other3DBooks = ((_a = product.handle) === null || _a === void 0 ? void 0 : _a.includes("kandinsky")) || ((_b = product.handle) === null || _b === void 0 ? void 0 : _b.includes("rosenquist"));
    const useBook = ((_c = product.handle) === null || _c === void 0 ? void 0 : _c.includes("isms")) || other3DBooks;
    let artist = product.handle.replace("-isms", "");
    if (useBook && other3DBooks) {
        artist = ((_d = product.handle) === null || _d === void 0 ? void 0 : _d.includes("kandinsky")) ? "kandinsky" : "rosenquist";
    }
    return (React.createElement(ModuleWrap, { ref: moduleRef, onMouseEnter: () => {
            if (hImg) {
                setImgSrc(hImg);
            }
        }, onMouseLeave: () => {
            setImgSrc(img);
        } },
        React.createElement("a", { href: `/product/${product.handle}` }, !product.handle.includes("holzer") && useBook && product.productType === "Books" ? (React.createElement(ImgWrap, { className: inViewport ? "in-view" : "" },
            React.createElement(Book3d, { artist: artist, isPdp: true, focused: true, isAbsolute: false }))) : (React.createElement(ImgWrap, { className: inViewport ? "in-view" : "" },
            React.createElement("img", { src: imgSrc })))),
        React.createElement("a", { href: `/product/${product.handle}` },
            React.createElement(Text, { size: "large", normalCase: true }, product.title)),
        product.variants && React.createElement(Text, { size: "small" },
            "$",
            product.variants[0].price.amount)));
};
export { ProductCard };
