import React from "react";
import { styled } from "linaria/react";
import { Text } from "@common";
import { mq } from "@styles";
const MQ = mq;
const Wrap = styled.div `
  max-width: 670px;
  width: 100%;
  margin: 0 auto 100px;

  img {
    width: 100%;
  }

  img + span {
    margin-top: 8px;
  }

  ${MQ.maxSm} {
    div {
      padding: 0 16px;
    }
  }
`;
const ArticleImage = ({ img, caption }) => {
    return (React.createElement(Wrap, null,
        img && React.createElement("img", { src: img }),
        React.createElement("div", null, caption && React.createElement(Text, { size: "small" }, caption))));
};
export { ArticleImage };
