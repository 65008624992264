var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useMemo } from "react";
import { Colors } from "@styles";
import { styled } from "linaria/react";
import { headline4, bodyText } from "@styles";
const Button = (_a) => {
    var { children, isHeadline, color, href, bgColor, hoverBg, onClick, hovering, openNewTab } = _a, props = __rest(_a, ["children", "isHeadline", "color", "href", "bgColor", "hoverBg", "onClick", "hovering", "openNewTab"]);
    const StyledA = useMemo(() => {
        if (isHeadline) {
            return styled.a `
        ${headline4}
        line-height: 1rem;
        color: ${() => color || "#000"};
        border: 1px solid ${() => color || "#000"};
        padding: 14px 14px 8px;
        display: inline;
        text-decoration: none;
        align-self: flex-start;
        text-transform: uppercase;
        background-color: ${() => (bgColor ? Colors[bgColor] : "transparent")};
        transition: color 0.5s, background-color 0.5s;

        &.hover {
          background-color: ${() => (hoverBg ? "#fff" : "#000")};
          color: ${() => (hoverBg ? Colors[hoverBg] : "#fff")};
          span {
            color: ${() => (hoverBg ? Colors[hoverBg] : "#fff")};
          }
        }

        &:hover {
          background-color: ${() => (hoverBg ? "#fff" : "#000")};
          color: ${() => (hoverBg ? Colors[hoverBg] : "#fff")};
          span {
            color: ${() => (hoverBg ? Colors[hoverBg] : "#fff")};
          }
        }
      `;
        }
        else {
            return styled.a `
        ${bodyText}
        color: ${() => color || "#000"};
        border: 1px solid ${() => color || "#000"};
        padding: 8px 14px;
        display: inline;
        text-decoration: none;
        align-self: flex-start;
        text-transform: uppercase;
        background-color: ${() => (bgColor ? Colors[bgColor] : "transparent")};
        transition: color 0.5s, background-color 0.5s;

        &.hover {
          background-color: ${() => (hoverBg ? "#fff" : "#000")};
          color: ${() => (hoverBg ? Colors[hoverBg] : "#fff")};
          span {
            color: ${() => (hoverBg ? Colors[hoverBg] : "#fff")};
          }
        }

        &:hover {
          background-color: ${() => (hoverBg ? "#fff" : "#000")};
          color: ${() => (hoverBg ? Colors[hoverBg] : "#fff")};
          span {
            color: ${() => (hoverBg ? Colors[hoverBg] : "#fff")};
          }
        }
      `;
        }
    }, [children, isHeadline, color, bgColor]);
    let targetProps = {};
    if (openNewTab && href) {
        targetProps.target = "_blank";
    }
    if (href &&
        (href.includes("https://") || href.includes("http://")) &&
        !href.includes("nmrnmr") &&
        !href.includes("nomorerulers")) {
        targetProps.target = "_blank";
    }
    return (StyledA && (React.createElement(StyledA, Object.assign({}, props, { href: href || "#" }, targetProps, { className: hovering ? "hover" : "", onClick: (e) => {
            if (onClick) {
                onClick(e);
            }
        } }),
        React.createElement("span", null, children))));
};
export { Button };
