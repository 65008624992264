var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from "react";
import { styled } from "linaria/react";
import { Button, Text } from "@common";
import { mutateApi } from "@utils";
import { mq, PORTRAIT, bodyText } from "@styles";
const MQ = mq;
const Form = styled.form `
  background: ${(props) => (props.inverted ? "transparent" : "#fff")};
  padding: 24px 0;
  display: flex;
  flex-direction: ${(props) => (props.vertical ? "column" : "row")};
  margin-top: ${(props) => (props.vertical ? 0 : "64px")};

  ${MQ.sm} {
    width: 100%;

    a {
      min-width: 100px;
      text-align: center;
    }
  }

  ${PORTRAIT} {
    width: 100%;

    a {
      min-width: 100px;
      text-align: center;
    }
  }

  ${MQ.maxSm} {
    margin-top: ${(props) => (props.vertical ? 0 : "30px")};
    width: 100%;
    padding-bottom: 0;

    a {
      min-width: 90px;
      text-align: center;
    }
  }
`;
const EmailInput = styled.input `
  width: ${(props) => (props.vertical ? "100%" : "765px")};
  margin-bottom: ${(props) => (props.vertical ? "24px" : 0)};
  height: 33px;
  border-radius: 0;
  border: 0;
  margin-right: 24px;
  font-family: "Diatype";
  text-transform: uppercase;
  ${bodyText}
  border-bottom: ${(props) => (props.inverted ? "1px solid #fff" : "1px solid #000")};
  color: ${(props) => (props.inverted ? "#fff" : "#000")};
  ::placeholder {
    color: ${(props) => (props.inverted ? "#fff" : "#000")};
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    color: ${(props) => (props.inverted ? "#fff" : "#000")};
  }

  ::-ms-input-placeholder {
    color: ${(props) => (props.inverted ? "#fff" : "#000")};
  }

  ${MQ.maxSm} {
    height: 33px;
    width: 100%;
    margin-right: 8px;
    border-bottom: ${(props) => (props.inverted ? "1px dotted #fff" : "1px dotted #000")};
  }
`;
const NewsletterForm = ({ vertical, inverted }) => {
    const [email, setEmail] = useState("");
    const [complete, setComplete] = useState(false);
    const [contactMutate] = mutateApi("contactsCreate", {
        email
    }, "POST");
    const handleSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        if (email) {
            setComplete(true);
            yield contactMutate().catch(() => null);
        }
    });
    return complete ? (React.createElement("div", { className: "newsletter-thank-you" },
        React.createElement(Text, { size: "body" }, "You're all set. See you in your inbox."))) : (React.createElement(Form, { vertical: vertical, inverted: inverted, onSubmit: (e) => {
            e.preventDefault();
            handleSubmit();
        } },
        React.createElement(EmailInput, { placeholder: "Enter your email", type: "email", vertical: vertical, inverted: inverted, value: email, onChange: (event) => {
                setEmail(event.target.value);
            } }),
        React.createElement(Button, { color: inverted ? "white" : "black", onClick: (e) => {
                e.preventDefault();
                handleSubmit();
            } }, "submit \u2192")));
};
export { NewsletterForm };
