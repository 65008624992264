import React from "react";
import { styled } from "linaria/react";
const HeroWrap = styled.div `
  width: 100vw;
  margin: 0 auto 100px;

  img {
    width: 100%;
  }
`;
const ArticleHero = ({ img }) => {
    return (React.createElement(HeroWrap, null,
        React.createElement("img", { src: img })));
};
export { ArticleHero };
