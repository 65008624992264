import React from "react";
import { AppContainer, Footer } from "@components";
import { styled } from "linaria/react";
import { Frame } from "@common";
import { QuoteModule } from "@components";
import { ArticleTitle } from "./ArticleTitle";
import { ArticleHero } from "./ArticleHero";
import { ArticleBody } from "./ArticleBody";
import { ArticleGallery } from "./ArticleGallery";
import { ArticleImage } from "./ArticleImage";
import { ArticleVideo } from "./ArticleVideo";
import { ArticleSubscribe } from "./ArticleSubscribe";
import { mq } from "@styles";
import { useParams, useHistory } from "react-router";
import { useApi } from "@utils";
const MQ = mq;
const ContentWrap = styled.div `
  background: #fff;
  width: 100vw;
  padding-bottom: 96px;
  position: sticky;

  ${MQ.maxSm} {
    padding-bottom: 64px;
  }
`;
const RelatedTitle = styled.div `
  text-align: center;
  margin-bottom: 32px;
`;
const RelatedWrap = styled.div `
  display: flex;

  ${MQ.maxSm} {
    flex-direction: column;
  }
`;
export const Article = () => {
    var _a, _b, _c;
    const history = useHistory();
    const params = useParams();
    const dataApi = useApi("articles", {}, {}, {
        slug: params.slug
    });
    if (!(dataApi === null || dataApi === void 0 ? void 0 : dataApi.data) || dataApi.isLoading) {
        return null;
    }
    const data = dataApi.data;
    if (!data || data.length === 0) {
        history.push("/");
        return null;
    }
    const article = data[0];
    const modules = [];
    article.ArticleContent.forEach((i) => {
        i.ArticleBody.forEach((aB) => {
            modules.push({
                ArticleBody: aB
            });
        });
        i.ArticleImage.forEach((aI) => {
            modules.push({
                ArticleImage: aI
            });
        });
        i.ArticleQuote.forEach((qM) => {
            modules.push({
                ArticleQuote: qM
            });
        });
        i.ArticleGallery.forEach((aG) => {
            modules.push({
                ArticleGallery: aG
            });
        });
        i.ArticleVideo.forEach((aV) => {
            modules.push({
                ArticleVideo: aV
            });
        });
    });
    return (React.createElement(AppContainer, null,
        React.createElement(Frame, null),
        React.createElement(ArticleTitle, { mainTitle: article.main_title, subTitle: article.sub_title, author: article.author, hasImage: !!((_a = article.featured_image) === null || _a === void 0 ? void 0 : _a.url) }),
        React.createElement(ContentWrap, null,
            ((_b = article.featured_image) === null || _b === void 0 ? void 0 : _b.url) && React.createElement(ArticleHero, { img: (_c = article.featured_image) === null || _c === void 0 ? void 0 : _c.url }),
            React.createElement(React.Fragment, null, modules.map((item) => {
                var _a, _b, _c;
                if (item["ArticleBody"]) {
                    const bodyItem = item["ArticleBody"];
                    return (React.createElement(ArticleBody, { key: `bodyItem-${bodyItem.id}`, subtitle: bodyItem.sub_title, body: bodyItem.content }));
                }
                if (item["ArticleImage"]) {
                    const imageItem = item["ArticleImage"];
                    return (React.createElement(ArticleImage, { key: `imageItem-${imageItem.id}`, caption: imageItem.caption, img: (_a = imageItem.image) === null || _a === void 0 ? void 0 : _a.url }));
                }
                if (item["ArticleQuote"]) {
                    const quoteItem = item["ArticleQuote"];
                    return (React.createElement(QuoteModule, { key: `quoteItem-${quoteItem.id}`, quote: quoteItem.quote, caption: `↑ ${quoteItem.person}`, bgColor: quoteItem.background_color, half: !!quoteItem.half_width, image: quoteItem.image, artist: quoteItem.artist }));
                }
                if (item["ArticleGallery"]) {
                    const galleryItem = item["ArticleGallery"];
                    const imageItems = galleryItem.images ? galleryItem.images : [];
                    return React.createElement(ArticleGallery, { key: `galleryItem-${galleryItem.id}`, images: imageItems });
                }
                if (item["ArticleVideo"]) {
                    const videoItem = item["ArticleVideo"];
                    return (React.createElement(ArticleVideo, { key: `videoItem-${videoItem.id}`, src: (_b = videoItem.video_file) === null || _b === void 0 ? void 0 : _b.url, title: videoItem.title, posterImg: (_c = videoItem.poster_image) === null || _c === void 0 ? void 0 : _c.url }));
                }
                return null;
            })),
            React.createElement(ArticleSubscribe, null)),
        React.createElement(Footer, null)));
};
