import React from "react";
import { styled } from "linaria/react";
import { NewsletterForm, Text, Headline } from "@common";
import { Colors, mq } from "@styles";
const MQ = mq;
const Wrap = styled.div `
  max-width: 1440px;
  width: 100%;
  background: ${Colors.yellow};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;

  ${MQ.maxSm} {
    margin: 0 auto;
  }
`;
const ContentWrap = styled.div `
  margin: 84px auto;
  max-width: 670px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  span.text-article {
    margin: 24px 0 0;
  }

  form {
    margin-top: 0;
    background: transparent !important;
    max-width: 670px;
    width: 100%;

    input {
      background-color: transparent !important;
    }

    a {
      min-width: 100px;
    }
  }

  ${MQ.maxSm} {
    padding: 0;
    margin: 0;

    form {
      flex-direction: column;
      padding-top: 0;

      input {
        margin: 0 24px;
        width: calc(100vw - 48px);
      }

      a {
        margin-top: 24px;
        width: 100%;
        background-color: black;

        span {
          color: white !important;
        }
      }
    }
  }
`;
const TextWrap = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${MQ.maxSm} {
    padding: 24px;
  }
`;
const ArticleSubscribe = () => {
    return (React.createElement(Wrap, null,
        React.createElement(ContentWrap, null,
            React.createElement(TextWrap, null,
                React.createElement(Headline, { size: 4 }, "Want to know more?"),
                React.createElement(Text, { size: "article", normalCase: true }, "Subscribe to our newsletter and gain access to exclusive interviews with industry creatives, think pieces, trend forecasts, guides and more")),
            React.createElement(NewsletterForm, null))));
};
export { ArticleSubscribe };
