import React, { useRef } from "react";
import { Colors } from "@styles";
import { styled } from "linaria/react";
import { Text, Jumbo, MiniFrame } from "@common";
import { mq, PORTRAIT, LANDSCAPE } from "@styles";
import { NavActions } from "@actions";
import { connect } from "react-redux";
import { formatSlug } from "@utils";
import { useHistory, useLocation } from "react-router";
import { useInViewport } from "react-in-viewport";
const MQ = mq;
const ModuleWrap = styled.div `
  width: ${(props) => (props.half ? "50vw" : "100vw")};
  background: ${(props) => (props.bgColor ? Colors[props.bgColor] : ``)};
  background-size: contain;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
  padding: 110px 70px;

  ${MQ.sm} {
    padding: 48px;
    width: 100vw;
  }

  ${PORTRAIT} {
    padding: 48px;
    width: 100vw;
  }

  ${MQ.maxSm} {
    width: 100vw;
    padding: 80px 32px;
  }
`;
const Caption = styled.div `
  margin-top: 32px;
  cursor: pointer;

  ${MQ.md} {
    margin-top: 32px;
  }

  ${LANDSCAPE} {
    margin-top: 32px;
  }

  ${MQ.maxSm} {
    margin-top: 16px;
  }
`;
const Wrap = styled.div `
  width: 100vw;
  position: relative;
  display: flex;
  flex-direction: row;
`;
const ImgWrap = styled.div `
  width: 50vw;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  &.in-view {
    opacity: 1;
  }

  img {
    object-fit: contain;
  }

  ${MQ.sm} {
    display: none;
  }

  ${PORTRAIT} {
    display: none;
  }

  ${MQ.maxSm} {
    display: none;
  }
`;
const XQuoteModule = ({ bgColor, caption, quote, half, image, artist, changeArtist }) => {
    const frameColor = bgColor === "red" ? "invertedRed" : bgColor === "blue" ? "invertedBlue" : "white";
    const history = useHistory();
    const pathname = useLocation().pathname.toLowerCase();
    const moduleRef = useRef(null);
    const { inViewport, enterCount, leaveCount } = useInViewport(moduleRef, { threshold: 0.5 }, { disconnectOnLeave: false }, {});
    // @ts-ignore
    const textColor = ["yellow", "red", "white"].includes(bgColor) ? "black" : "white";
    return (React.createElement(Wrap, { ref: moduleRef },
        React.createElement(MiniFrame, { color: frameColor }),
        React.createElement(ModuleWrap, { bgColor: bgColor, half: half },
            quote && (React.createElement(Jumbo, { size: "max", color: textColor }, quote)),
            caption && (React.createElement(Caption, { onClick: () => {
                    if (artist) {
                        const artistSlug = formatSlug(artist.name);
                        if (artistSlug) {
                            changeArtist && changeArtist(artistSlug);
                            if (pathname !== `/${artistSlug}`) {
                                history.push(`/${artistSlug}`);
                            }
                            window.scrollTo(0, 0);
                        }
                    }
                } },
                React.createElement(Text, { size: "body", color: textColor }, caption)))),
        half && image && (React.createElement(ImgWrap, { className: inViewport ? "in-view" : "", style: { backgroundImage: `url(${image.url})` } }))));
};
const mapStateToProps = ({ nav, cart }) => {
    return { nav, cart };
};
export const QuoteModule = connect(mapStateToProps, {
    changeNav: NavActions.changeNav,
    changeArtist: NavActions.changeArtist
})(XQuoteModule);
