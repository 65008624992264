import React, { Fragment } from "react";
import { Colors } from "@styles";
import { styled } from "linaria/react";
import { Text, Jumbo, NewsletterForm } from "@common";
import { mq } from "@styles";
import { useHistory, useLocation } from "react-router";
import { NavActions } from "@actions";
import { connect } from "react-redux";
const MQ = mq;
const ModuleWrap = styled.div `
  width: 100vw;
  background: ${(props) => (props.bgColor ? Colors[props.bgColor] : ``)};
  background-size: contain;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
  padding: 70px;

  form {
    background: ${(props) => (props.bgColor ? Colors[props.bgColor] : ``)};
  }

  img {
    height: 5vmin;
  }

  span + span {
    margin-top: 18px;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  .jumbo-newsletter span a:nth-child(even) {
    &:hover {
      color: ${Colors.red};
      transition: color 0.3s;
    }
  }

  .jumbo-newsletter span a:nth-child(odd) {
    &:hover {
      color: ${Colors.blue};
      transition: color 0.3s;
    }
  }

  ${MQ.maxSm} {
    padding: 67px 27px;

    span img {
      height: 5.5vmin;
    }
  }
`;
const ClickSpan = styled.span `
  cursor: pointer;
`;
const XNewsletterModule = ({ bgColor, title, articles, changeNav }) => {
    const history = useHistory();
    const pathname = useLocation().pathname.toLowerCase();
    return (React.createElement(ModuleWrap, { bgColor: bgColor },
        React.createElement(Text, { size: "body" },
            React.createElement(React.Fragment, null,
                `${title} `,
                React.createElement(ClickSpan, { onClick: () => {
                        changeNav && changeNav("newsletter");
                        if (pathname !== "/newsletter") {
                            history.push("/newsletter");
                        }
                        window.scrollTo(0, 0);
                    } }, "\u2193 SEE ARCHIVE"))),
        React.createElement(Jumbo, { size: "newsletter" },
            React.createElement("span", null, articles.map((item) => {
                var _a, _b, _c, _d;
                return (React.createElement(Fragment, { key: `articleItem-${item.id}` }, item.text_url ? (React.createElement("a", { href: item.text_url, target: "_blank" },
                    ((_a = item.image) === null || _a === void 0 ? void 0 : _a.url) && React.createElement("img", { src: (_b = item.image) === null || _b === void 0 ? void 0 : _b.url }),
                    ` ${item.title} `)) : (React.createElement(React.Fragment, null,
                    ((_c = item.image) === null || _c === void 0 ? void 0 : _c.url) && React.createElement("img", { src: (_d = item.image) === null || _d === void 0 ? void 0 : _d.url }),
                    ` ${item.title} `))));
            }))),
        React.createElement(NewsletterForm, null)));
};
const mapStateToProps = ({ nav, cart }) => {
    return { nav, cart };
};
export const NewsletterModule = connect(mapStateToProps, {
    changeNav: NavActions.changeNav,
    changeArtist: NavActions.changeArtist
})(XNewsletterModule);
