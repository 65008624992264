import React, { useEffect, useState, useRef } from "react";
import { styled } from "linaria/react";
import { AppContainer, Footer, ContentModule } from "@components";
import { Frame, Text } from "@common";
import { ProductCard } from "./ProductCard";
import { mq, PORTRAIT } from "@styles";
import { connect } from "react-redux";
import { CartActions } from "@actions";
import { useApi } from "@utils";
import queryString from "query-string";
import ObjectHash from "object-hash";
import Client from "shopify-buy";
const client = Client.buildClient({
    domain: "nomorerulers.myshopify.com",
    storefrontAccessToken: "79d98281b869d4d66d2f6148344847f4"
});
const MQ = mq;
const ShopNav = styled.ul `
  margin: 0;
  padding-inline-start: 0;
  height: 156px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  background: #fff;

  li {
    display: inline-block;
    & + li {
      margin-left: 16px;
    }
    a {
      text-decoration: none;
    }
  }

  ${MQ.maxSm} {
    margin-top: 60px;
    height: 76px;
  }
`;
const ProductRow = styled.div `
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background: #fff;
`;
const ProductRowWrap = styled.div `
  margin: 0 auto;
  max-width: 1628px;
  display: grid;
  grid-template-columns: auto auto auto;
  justify-items: center;
  width: calc(100vw - 96px);
  background: #fff;

  ${MQ.smd} {
    grid-template-columns: auto auto;
  }

  ${MQ.sm} {
    grid-template-columns: auto auto;
  }

  ${PORTRAIT} {
    grid-template-columns: auto auto;
  }

  ${MQ.maxSm} {
    width: calc(100vw);
    grid-template-columns: auto;
  }
`;
const BlackScreen = styled.div `
  background: #000;
  width: 100vw;
  height: 100vh;
  height: calc((var(--vh, 1vh) * 100));
  position: fixed;
  z-index: 2;
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: 2.5s opacity ease-in-out;
`;
const PreviewModuleWrap = styled.div `
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
  justify-content: center;
  align-items: center;
  max-width: 320px;
  text-align: center;

  a {
    text-decoration: none;
    margin: 24px 0 8px;
  }

  img {
    max-width: 100%;
  }

  img + span {
    margin-top: 16px;
  }

  a span {
    text-align: center;
  }

  span + span {
    margin-top: 16px;
  }

  ${MQ.sm} {
    width: 50%;
  }

  ${PORTRAIT} {
    width: 50%;
  }

  ${MQ.maxSm} {
    width: 100%;
    padding-bottom: 40px;
    max-width: 100%;
  }
`;
const PreviewImgWrap = styled.div `
  height: 425px;
  max-height: 425px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  &.in-view {
    opacity: 1;
  }
`;
const FILTERS = ["All", "Books", "Apparel", "Artwork"];
const PRODUCT_ORDER = [
    "jean-michel-basquiat-handbook",
    "kaws-handbook",
    "holzer-isms",
    "neshat-isms",
    "abramovic-isms",
    "jr-isms",
    `warhol-isms`,
    `keith-haring-jean-michel-basquiat-crossing-lines`,
    `daniel-arsham-sketchbook`,
    `keith-haring-31-subway-drawings-1`,
    `subway-drawings-still-alive-tee`,
    `the-notebooks-by-jean-michel-basquiat`,
    `royalty-tee`,
    `love-is-a-lie-tee`,
    `athletes-tee-in-black-jmb-the-notebooks`,
    `athletes-tee`,
    `nmr-no-more-rulers-hat`,
    `arsham-isms`,
    `abloh-isms`,
    `futura-isms`,
    `haring-isms`,
    `basquiat-isms`,
    `weiwei-isms`,
    `nmr-icon-tee-black`,
    `nmr-icon-tee-cream`,
    `nmr-icon-cap`,
    `nmr-logo-tee-black`,
    `nmr-logo-tee-white`
];
const XShop = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    const dataApi = useApi("storePage", {});
    const [data, setData] = useState(dataApi.data);
    const availableProductsDataApi = useApi("availableProducts", {});
    const [availableProductsData, setAvailableProductsData] = useState(availableProductsDataApi.data);
    const [productOrder, setProductOrder] = useState(PRODUCT_ORDER);
    const [filter, setFilter] = useState("All");
    const [products, setProducts] = useState({});
    const [categories, setCategories] = useState({});
    const [hideBlackSeen, setHideBlackScreen] = useState(false);
    const queryParams = queryString.parse(window.location.search);
    const blackRef = useRef(true);
    useEffect(() => {
        if (!availableProductsDataApi.isLoading) {
            setProductOrder(availableProductsDataApi.data.shopify_ids.split("\n"));
        }
    }, [availableProductsDataApi === null || availableProductsDataApi === void 0 ? void 0 : availableProductsDataApi.isLoading]);
    useEffect(() => {
        client.product.fetchAll(150).then((products) => {
            const beforeProducts = {};
            const beforeCategories = {
                All: 0
            };
            Object.values(products).forEach((productInfo) => {
                // @ts-ignore
                if (productInfo.productType) {
                    // @ts-ignore
                    beforeProducts[productInfo.handle] = productInfo;
                    // @ts-ignore
                    if (beforeCategories[productInfo.productType]) {
                        // @ts-ignore
                        beforeCategories[productInfo.productType] += 1;
                    }
                    else {
                        // @ts-ignore
                        beforeCategories[productInfo.productType] = 1;
                    }
                    beforeCategories["All"] += 1;
                }
            });
            console.log({ apiProducts: beforeProducts });
            setCategories(beforeCategories);
            const sortedProducts = {};
            productOrder.forEach((key) => {
                sortedProducts[key] = beforeProducts[key];
            });
            setProducts(sortedProducts);
        });
    }, [ObjectHash(productOrder)]);
    console.log({ products });
    useEffect(() => {
        var _a;
        if (!((_a = props.cart) === null || _a === void 0 ? void 0 : _a.checkoutId)) {
            client.checkout.create().then((checkout) => {
                // @ts-ignore
                props.createCheckout && props.createCheckout(checkout.id);
            });
        }
    }, [(_a = props.cart) === null || _a === void 0 ? void 0 : _a.checkoutId]);
    useEffect(() => {
        if (!dataApi.isLoading) {
            setTimeout(() => {
                blackRef.current = false;
                setHideBlackScreen(true);
            }, 2500);
        }
    }, [dataApi.isLoading]);
    const contentItem = dataApi.data && dataApi.data.StoreHero && dataApi.data.StoreHero[0];
    return (React.createElement(AppContainer, null,
        React.createElement(Frame, null),
        !hideBlackSeen && blackRef.current && React.createElement(BlackScreen, { show: dataApi.isLoading }),
        contentItem && (React.createElement(ContentModule, { key: `contentItem-${contentItem.id}`, cloudflareVideoId: contentItem.cloudflare_video_id, mobileCloudflareVideoId: contentItem.mobile_cloudflare_video_id, bgImg: (_b = contentItem.background_image) === null || _b === void 0 ? void 0 : _b.url, cardPosition: contentItem.card_position, typeText: contentItem.type_text, headlineText: contentItem.headline_text, bodyText: contentItem.body_text, ctaText: contentItem.button_text, ctaUrl: contentItem.button_url, centerImg: (_c = contentItem.featured_image) === null || _c === void 0 ? void 0 : _c.url, cImgHeight: (_d = contentItem.featured_image) === null || _d === void 0 ? void 0 : _d.height, cImgWidth: (_e = contentItem.featured_image) === null || _e === void 0 ? void 0 : _e.width, imgWidth: (_f = contentItem.background_image) === null || _f === void 0 ? void 0 : _f.width, imgHeight: (_g = contentItem.background_image) === null || _g === void 0 ? void 0 : _g.height, caption: contentItem.caption, mBgImg: (_h = contentItem.mobile_background_image) === null || _h === void 0 ? void 0 : _h.url, mImgWidth: (_j = contentItem.mobile_background_image) === null || _j === void 0 ? void 0 : _j.width, mImgHeight: (_k = contentItem.mobile_background_image) === null || _k === void 0 ? void 0 : _k.height, bgColor: contentItem.background_color, colorCard: !!contentItem.background_color, half: !!contentItem.half_width, vhHeight: true })),
        React.createElement(ShopNav, null, FILTERS.map((category) => (React.createElement("li", { key: category }, categories[category] ? (React.createElement("a", { href: "#", onClick: (e) => {
                e.preventDefault();
                setFilter(category);
            } },
            React.createElement(Text, { size: "body", color: filter === category ? "black" : "charcoal", underline: filter === category }, category),
            React.createElement("sup", null,
                React.createElement(Text, { size: "detail", color: filter === category ? "black" : "charcoal" }, categories[category])))) : (React.createElement(React.Fragment, null,
            React.createElement(Text, { size: "body", color: filter === category ? "black" : "textGrey", underline: filter === category }, category),
            React.createElement("sup", null,
                React.createElement(Text, { size: "detail", color: "textGrey" }, "0")))))))),
        React.createElement(ProductRow, null,
            React.createElement(ProductRowWrap, null, Object.entries(products).map(([handle, product]) => product && !!handle && (filter === "All" || product.productType === filter) ? (React.createElement(ProductCard, { key: handle, product: product, img: product.images[0].src, hImg: product.images && product.images[1] ? product.images[1].src : undefined })) : null))),
        React.createElement(Footer, null)));
};
const mapStateToProps = ({ nav, cart }) => {
    return { nav, cart };
};
export const Shop = connect(mapStateToProps, {
    toggleCartOpen: CartActions.toggleCartOpen,
    createCheckout: CartActions.createCheckout
})(XShop);
